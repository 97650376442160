import get from 'lodash/get';
import map from 'lodash/map';
import set from 'lodash/set';
import * as xlsx from 'xlsx';

import { Document } from './documents.types';

type ExcelColumn = {
  width: number;
  col: keyof Document;
};

export const excelColumns: ExcelColumn[] = [
  {
    col: 'documentDate',
    width: 12,
  },
  {
    col: 'documentNumber',
    width: 30,
  },

  {
    col: 'title',
    width: 60,
  },

  {
    col: 'mainType',
    width: 10,
  },
  {
    col: 'subType',
    width: 25,
  },
  {
    col: 'visibility',
    width: 10,
  },
  {
    col: 'productReferenceTypes',
    width: 25,
  },
  {
    col: 'tkbURL',
    width: 70,
  },
  {
    col: 'wartsilaOnlineURL',
    width: 70,
  },
];

type CreateExcelFileParams = {
  sheet: string;
  data: { t: string; v: string }[][];
  filename: string;
  dictionary: { [key: string]: string };
  columns: {
    width: number;
    col: keyof Document;
  }[];
};

export const getExcelFile = ({
  data,
  sheet,
  columns,
  filename,
  dictionary,
}: CreateExcelFileParams): void => {
  const workbook = xlsx.utils.book_new();
  workbook.SheetNames.push(sheet);

  const header = map(columns, (column) => get(dictionary, column.col));
  const worksheet = xlsx.utils.aoa_to_sheet([header, ...data]);

  set(worksheet, '!cols', columns);
  set(workbook.Sheets, sheet, worksheet);

  xlsx.writeFile(workbook, `${filename}.xlsx`);
};
