import * as React from 'react';
import './app.styles.scss';

import UIKit, * as WUI from '@wartsila/ui-kit';
import { ErrorBoundary } from 'react-error-boundary';
import { ReactComponent as NightIconSVG } from '@wartsila/ui-kit/icons/night.svg';
import { OptionTypeBase } from 'react-select';
import ProblemIconSVG from '@wartsila/ui-kit/icons/problem.svg';
import { ReactComponent as SunIconSVG } from '@wartsila/ui-kit/icons/sun.svg';
import assign from 'lodash/assign';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import set from 'lodash/set';
import isNull from 'lodash/isNull';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { useInView } from 'react-intersection-observer';
import {
  AdvancedFilters,
  AssignmentFilters,
  DocumentSetsFilters,
  DocumentFilters,
  FilterContentLoader,
} from './features/filters/filters.components';
import {
  AdvancedSearchFilter,
  AssignmentsFilter,
  DefaultFilters,
  FilterValue,
} from './features/filters/filters.types';
import {
  Document,
  DocumentsResponse,
} from './features/documents/documents.types';
import { Tab, Tabs } from './components/Tabs';
import {
  useDocumentAdvancedSearch,
  useDocumentSearch,
  useDocumentTypeFilters,
  useDocuments,
  useDocumentsForExport,
  useTrackDocumentSearch,
} from './features/documents/documents.hooks';

import { Assignments } from './features/assignments/assignments.components';
import { DocumentContent } from './features/documentContent/documentContent.components';
import { DocumentData } from './features/documentData/documentData.components';
import { DocumentsTable } from './features/documents/documents.components';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Icon } from './components/Icon';
import { Label } from './components/Label';
import { Nav } from './components/Nav';
import { SubscriptionModal } from './features/subscriptions/subscriptions.components';
import { Techrequest } from './features/techrequest/techrequest.components';
import { TextArea } from './components/TextArea';
import { UpdateNote } from './features/updateNote/updateNote.component';
/* eslint-disable react/no-unescaped-entities */
import { filterDictionary } from './features/filters/filters.dictionary';
import { queryClient } from '.';
import { useAuth } from './features/auth/auth.hooks';
import { useContact } from './hooks/useContact';
import { useDownload } from './features/file/file.hooks';
import { useFilterTypes } from './features/filters/filters.hooks';
import { useTheme } from './features/theme/theme.hooks';
import useSessionStorage from './hooks/useSessionStorage';
import { MissingDocRequest } from './features/docrequest/docrequest.component';

const sortingOptions = [
  { label: 'Most relevant', value: '' },
  { label: 'Newest first', value: 'date_desc' },
  { label: 'Oldest first', value: 'date_asc' },
  { label: 'Document number (A-Z)', value: 'documentNumber_asc' },
  { label: 'Document title (A-Z)', value: 'title_asc' },
];

export default function App({
  defaultFilters,
}: {
  defaultFilters?: DefaultFilters;
}): JSX.Element {
  const contact = useContact();
  const { config } = useAuth();
  const appEnvironment = config?.REACT_APP_ENVIRONMENT as string | undefined;
  const theme = useTheme();
  const filterTypes = useFilterTypes();

  const { ref, inView } = useInView({ initialInView: true });

  const [selectedDocuments, selectDocuments] = React.useState<Document[]>([]);

  const { downloading, download, MAX_DOWNLOAD_LIMIT } = useDownload();

  const [infoModalOpened, setInfoModal] = React.useState(false);
  const toggleInfoModal = (): void => setInfoModal((state) => !state);

  const [subscriptionsOpened, setSubscriptionsOpened] = React.useState(false);
  const toggleSubscriptions = (): void => setSubscriptionsOpened((s) => !s);

  const [searchInput, setSearchInput] = React.useState<string>(
    defaultFilters?.search || ''
  );
  const { search, setSearch } = useDocumentSearch(defaultFilters?.search);

  const trackTextSearch = useTrackDocumentSearch();

  const [session, selectFiltersForSession] = useSessionStorage<{
    selectedAssignmentFilters: Partial<AssignmentsFilter>;
    selectedDocumentSets: string[];
    selectedDocumentTypes: string[];
    documentCategories: string[];
    advancedSearch: Partial<AdvancedSearchFilter>;
  }>('session-filters', {
    selectedAssignmentFilters: {},
    selectedDocumentSets: [],
    selectedDocumentTypes: [],
    documentCategories: [],
    advancedSearch: {},
  });

  const hasSessionAdvancedSearchFilters = Boolean(
    session.advancedSearch ? Object.keys(session.advancedSearch).length : 0
  );

  const defaultAdvancedSearchFilters = hasSessionAdvancedSearchFilters
    ? session.advancedSearch
    : {
        id: defaultFilters?.id,
        title: defaultFilters?.title,
        documentNumber: defaultFilters?.documentNumber,
        hasVideos: defaultFilters?.hasVideos,
        isBookmarked: defaultFilters?.isBookmarked,
        dateFrom: defaultFilters?.dateFrom,
        dateTo: defaultFilters?.dateTo,
        serviceProductCode: defaultFilters?.advancedFilters?.serviceProductCode,
      };

  const { advancedSearch, debouncedAdvancedSearch, setAdvancedSearch } =
    useDocumentAdvancedSearch(defaultAdvancedSearchFilters);

  const hasSessionDocumentTypeFilters = Boolean(
    session.selectedDocumentTypes?.length || 0
  );

  const defaultDocumentTypeFilters: string[] = hasSessionDocumentTypeFilters
    ? session.selectedDocumentTypes
    : [];

  const [selectedDocumentTypes, selectDocumentTypes] = React.useState<string[]>(
    defaultDocumentTypeFilters
  );

  const hasSessionDocumentCategoriesFilters = Boolean(
    session.documentCategories.length || 0
  );

  const defaultDocumentCategoriesFilters: string[] =
    hasSessionDocumentCategoriesFilters ? session.documentCategories : [];

  const [documentCategories, setDocumentCategories] = React.useState<string[]>(
    defaultFilters?.visibility || defaultDocumentCategoriesFilters
  );

  const hasSessionAssignmentFilters = Boolean(
    Object.keys(session.selectedAssignmentFilters).length
  );
  const defaultAssignmentFilters = hasSessionAssignmentFilters
    ? session.selectedAssignmentFilters
    : defaultFilters?.assignmentFilters;

  const [selectedAssignmentFilters, selectAssigmentFilters] = React.useState<
    Partial<AssignmentsFilter>
  >(defaultAssignmentFilters || {});

  // START - warning message functionality (STKB-974) when prt or equipment's prt is inactive
  const [warningsShowedFor, setWarningsShowedFor] = React.useState<string[]>(
    []
  );

  React.useEffect(() => {
    const { equipment, productReferenceType } = selectedAssignmentFilters;
    // filters order matters - first check prt as equipment gets the status from it
    const filters = productReferenceType ?? equipment;
    if (!filters) return;

    const newInactiveFilters = filters.filter(
      (filter) => !warningsShowedFor.includes(filter.label) && !filter.active
    );

    if (newInactiveFilters.length) {
      setWarningsShowedFor((warnings) =>
        warnings.concat(newInactiveFilters.map((f) => f.label))
      );

      newInactiveFilters.forEach((f) => {
        // only equipment filter has productReferenceTypeLabel
        // if productReferenceTypeLabel not present, use label instead from productReferenceType filter
        const label = f.productReferenceTypeLabel ?? f.label;

        UIKit.showToast(
          `Product Reference Type ${label} has been set to status Inactive in SAP. This means that no Wärtsilä business unit is supporting ${label} anymore.`,
          {
            timeout: 9_000,
            title: 'Product Reference Type Inactive!',
            variant: WUI.ToastVariant.Warning,
            position: WUI.ToastPosition.BottomRight,
          }
        );
      });
    }
  }, [selectedAssignmentFilters]);

  // END - warning message functionality

  const hasSessionDocumentSetsFilters = Boolean(
    session.selectedDocumentSets.length
  );

  const defaultDocumentSetsFilters: string[] = hasSessionDocumentSetsFilters
    ? session.selectedDocumentSets
    : [];

  const [selectedDocumentSets, selectDocumentSets] = React.useState<string[]>(
    defaultFilters?.documentSet || defaultDocumentSetsFilters
  );

  const [dirty, setDirty] = React.useState(false);

  const documentsFilters: Record<string, string> = {};

  keys(selectedAssignmentFilters).forEach((filter) => {
    const options: string = get(selectedAssignmentFilters, filter)
      .map((option: FilterValue) => option.value)
      .toString();

    if (options) set(documentsFilters, filter, options);
  });

  if (advancedSearch.serviceProductCode)
    set(
      documentsFilters,
      'serviceProductCode',
      advancedSearch.serviceProductCode
        .map((option: FilterValue) => option.value)
        .toString()
    );

  const [forImmediateAttention, setImmediateAttention] = React.useState(
    Boolean(defaultFilters?.forImmediateAttention)
  );

  const documentTypeFilters = useDocumentTypeFilters({
    documentTypes: filterTypes.data?.documentTypes,
    forImmediateAttention,
    selectedDocumentTypes,
  });

  const checkHasDefaultFilters = (): boolean => {
    if (!defaultFilters) return false;
    const genericFilters = omit(defaultFilters, [
      'assignmentFilters',
      'advancedFilters',
    ]);
    const { assignmentFilters, advancedFilters } = defaultFilters;
    if (
      isEmpty(assignmentFilters) &&
      isEmpty(advancedFilters) &&
      isEmpty(genericFilters)
    )
      return false;
    return true;
  };

  const hasDefaultFilters = checkHasDefaultFilters();

  const [sorting, setSorting] = React.useState<OptionTypeBase>(
    sortingOptions[1]
  );

  // set to most relevant sorting when search query param is used (at startup)
  React.useEffect(() => {
    if (search) {
      setSorting(sortingOptions[0]);
    }
  }, []);

  React.useEffect(() => {
    if (search && !searchInput) {
      setSearch('');
      setSorting(sortingOptions[1]);
    }
  }, [search, searchInput]);

  // Apply default filters from query string
  React.useEffect(() => {
    if (filterTypes.isSuccess && defaultFilters?.documentTypes) {
      const { maintype, subtype } = defaultFilters?.documentTypes;

      if (!maintype) {
        if (subtype) selectDocumentTypes(subtype);
      } else {
        const allTypes: string[] = [];
        maintype.forEach((type: string) => {
          const filterValue: FilterValue | undefined = find(
            filterTypes.data.documentTypes,
            (documentType) => documentType.value === type
          );

          if (filterValue) {
            const { value, children } = filterValue;
            if (children) {
              children.forEach((child) => allTypes.push(child.value));
            } else {
              allTypes.push(value);
            }
          }
        });

        selectDocumentTypes(allTypes);
      }
    }
  }, [filterTypes.isSuccess]);

  assign(documentsFilters, debouncedAdvancedSearch, {
    search,
    documentType: documentTypeFilters,
    category: documentCategories.toString(),
    documentSet: selectedDocumentSets.toString(),
  });

  const documents = useDocuments({
    limit: 200,
    filters: documentsFilters,
    sortByField: sorting.value,
  });

  React.useEffect(() => {
    if (!isEmpty(documentsFilters)) {
      if (!documents.isFetched) {
        documents.fetchNextPage();
      }
    }
  }, [documents.isFetched]);

  React.useEffect(() => {
    if (inView && documents.hasNextPage && !documents.isFetchingNextPage) {
      documents.fetchNextPage();
    }
  }, [inView]);

  React.useEffect(() => {
    selectDocuments([]);
    queryClient.removeQueries(['document', 'grouped']);
  }, [JSON.stringify(documentsFilters)]);

  React.useEffect(() => {
    if (hasDefaultFilters) {
      sessionStorage.clear();
    }
  }, [hasDefaultFilters]);

  React.useEffect(() => {
    if (!dirty) return;
    if (!hasDefaultFilters) {
      selectFiltersForSession({
        selectedAssignmentFilters,
        selectedDocumentSets,
        selectedDocumentTypes,
        documentCategories,
        advancedSearch,
      });
    }
  }, [
    selectedAssignmentFilters,
    selectedDocumentSets,
    selectedDocumentTypes,
    documentCategories,
    advancedSearch,
    dirty,
  ]);

  const documentsToExport = useDocumentsForExport({
    limit: 750,
    filters: documentsFilters,
  });

  const toggleImmediateAttention = (): void => {
    setImmediateAttention((state) => !state);
  };

  /*
  When pressing the copy link, the following should be present in the query parameters of the copied link:
  'search', 'id', 'endDate' ('dateTo'), 'subtype', 'visibility', 'documentSet', 'doc', 'startDate' (dateFrom), 
  'hasVideos', 'serviceProductCode' (WAMS), 'customer', 'equipno', 'withinMyBookmarks' ('isBookmarked'), 'portfolio', 'networkCompany', 'producttype', 'prt', 
  'installation', 'installationCategory', 'title', 'forImmediateAttention'
  */
  const generateUrlToCopy = (): string => {
    const currentUrl = new URL(window.location.origin);
    const params = new URLSearchParams(currentUrl.search);

    const mapper = <T,>(element: T): string => {
      if (isArray(element)) return element.map(mapper).join(',');
      if (isObject(element)) {
        if (isNull(element)) return '';
        if ('value' in element) return String(element.value);
      }
      return String(element);
    };

    const filterToQueryParams = {
      search: searchInput,
      id: advancedSearch.id,
      title: advancedSearch.title,
      dateTo: advancedSearch.dateTo,
      subtype: selectedDocumentTypes,
      visibility: documentCategories,
      documentSet: selectedDocumentSets,
      doc: advancedSearch.documentNumber,
      dateFrom: advancedSearch.dateFrom,
      hasVideos: advancedSearch.hasVideos,
      serviceProductCode: advancedSearch.serviceProductCode,
      customer: selectedAssignmentFilters.customer,
      equipno: selectedAssignmentFilters.equipment,
      isBookmarked: advancedSearch.isBookmarked,
      portfolio: selectedAssignmentFilters.portfolio,
      networkCompany: selectedAssignmentFilters.networkCompany,
      producttype: selectedAssignmentFilters.productType,
      prt: selectedAssignmentFilters.productReferenceType,
      installation: selectedAssignmentFilters.installation,
      installationCategory: selectedAssignmentFilters.installationCategory,
      forImmediateAttention,
    };

    Object.keys(filterToQueryParams).forEach((key) => {
      const value = get(filterToQueryParams, key, '');
      const mappedValue = mapper(value);
      if (mappedValue) params.set(key, mappedValue);
    });

    return currentUrl.href.concat('?', params.toString());
  };

  return (
    <>
      <Header
        title="Technical Knowledge Base"
        appEnvironment={appEnvironment}
        nav={
          <Nav>
            {filterTypes.isSuccess ? (
              <WUI.Button
                onClick={() =>
                  contact.data?.emailSubscriptionDisabled
                    ? UIKit.showToast(
                        'Please use old TKB to manage your article subscriptions for now. The functionality here in new TKB will become available for you later.',
                        {
                          timeout: 5000,
                          variant: WUI.ToastVariant.Info,
                          position: WUI.ToastPosition.TopRight,
                        }
                      )
                    : toggleSubscriptions()
                }
                variant={WUI.ButtonVariant.Accent}>
                Subscriptions
              </WUI.Button>
            ) : null}
            <Icon
              large
              onClick={theme.toggle}
              component={
                theme.isDark ? (
                  <SunIconSVG title="Switch to light mode" />
                ) : (
                  <NightIconSVG title="Switch to dark mode" />
                )
              }
            />
          </Nav>
        }
      />
      <div className="content">
        <div
          className="content__sidebar"
          role="button"
          tabIndex={0}
          onClick={() => setDirty(true)}
          onKeyPress={() => setDirty(true)}>
          <div className="filters">
            <div className="filters__filter">
              <Label
                title="Text search"
                icon={
                  <Icon
                    src={ProblemIconSVG}
                    onClick={toggleInfoModal}
                    alt="More information"
                  />
                }>
                <TextArea
                  value={searchInput}
                  className="filters__textarea"
                  placeholder="Enter text here..."
                  onChange={(event) => {
                    event.persist();
                    setSearchInput(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                      event.preventDefault();
                      setSearch(searchInput);
                      trackTextSearch.mutate(searchInput);
                      setSorting(
                        searchInput ? sortingOptions[0] : sortingOptions[1]
                      );
                    }
                  }}
                />
              </Label>
              <div className="flex-container">
                <WUI.Button
                  disabled={documents.isFetching}
                  onClick={() => {
                    setSearch(searchInput);
                    trackTextSearch.mutate(searchInput);
                    setSorting(
                      searchInput ? sortingOptions[0] : sortingOptions[1]
                    );
                  }}>
                  Search
                </WUI.Button>
                <WUI.Button
                  disabled={
                    !hasSessionAssignmentFilters &&
                    !hasSessionDocumentSetsFilters &&
                    !hasSessionDocumentTypeFilters &&
                    !hasSessionDocumentCategoriesFilters &&
                    !hasSessionAdvancedSearchFilters &&
                    !searchInput &&
                    !forImmediateAttention
                  }
                  onClick={() => {
                    const urlToCopy = generateUrlToCopy();
                    navigator.clipboard.writeText(urlToCopy).then(() => {
                      UIKit.showToast(
                        'Success! Link copied to the clipboard.',
                        {
                          timeout: 5000,
                          variant: WUI.ToastVariant.Success,
                          title: 'Copy to clipboard',
                          position: WUI.ToastPosition.BottomRight,
                        }
                      );
                    });
                  }}>
                  Copy link
                </WUI.Button>
              </div>
            </div>

            {(filterTypes.isFetching || filterTypes.isLoading) && (
              <FilterContentLoader />
            )}
            {filterTypes.data ? (
              <>
                <AssignmentFilters
                  selected={selectedAssignmentFilters}
                  open={isEmpty(defaultFilters?.documentNumber)}
                  title={get(filterDictionary, 'assignments')}
                  filters={get(filterTypes.data, 'assignments')}
                  preload={['portfolio', 'installationCategory']}
                  dependencies={{
                    installation: ['customer', 'equipment'],
                    productReferenceType: [
                      'customer',
                      'portfolio',
                      'equipment',
                      'installation',
                    ],
                    productType: ['productReferenceType'],
                    equipment: [
                      'customer',
                      'installation',
                      'portfolio',
                      'productReferenceType',
                    ],
                    portfolio: [
                      'customer',
                      'installation',
                      'productReferenceType',
                    ],
                  }}
                  select={(selection) =>
                    selectAssigmentFilters((state) =>
                      omitBy(assign({ ...state }, selection), isEmpty)
                    )
                  }
                />

                <DocumentSetsFilters
                  title={get(filterDictionary, 'documentSets')}
                  selected={selectedDocumentSets}
                  select={selectDocumentSets}
                  filters={get(filterTypes.data, 'documentSets')}
                />

                <DocumentFilters
                  showedImmediateAttention
                  select={selectDocumentTypes}
                  selected={selectedDocumentTypes}
                  forImmediateAttention={forImmediateAttention}
                  title={get(filterDictionary, 'documentTypes')}
                  filters={get(filterTypes.data, 'documentTypes')}
                  toggleImmediateAttention={toggleImmediateAttention}
                />

                <DocumentFilters
                  selected={documentCategories}
                  select={setDocumentCategories}
                  title={get(filterDictionary, 'documentCategories')}
                  filters={get(filterTypes.data, 'documentCategories')}
                />

                <AdvancedFilters
                  serviceProductCodeOptions={get(
                    filterTypes.data.advancedSearch,
                    'serviceProductCode'
                  )}
                  selected={advancedSearch}
                  open={!isEmpty(defaultFilters?.documentNumber)}
                  title={get(filterDictionary, 'advancedSearch')}
                  select={(selection) =>
                    setAdvancedSearch((state) =>
                      assign({ ...state }, selection)
                    )
                  }
                  resetDateRange={() =>
                    setAdvancedSearch((state) =>
                      omit(state, ['dateTo', 'dateFrom'])
                    )
                  }
                />
              </>
            ) : null}
          </div>
        </div>

        <main className="content__main">
          <ErrorBoundary
            FallbackComponent={() => <div>Unable to load documents</div>}>
            {!documents.all.length && documents.isFetching ? (
              <WUI.Loader />
            ) : null}
            {documents.isSuccess ? (
              <>
                <DocumentsTable
                  downloading={downloading}
                  filters={documentsFilters}
                  selectRows={selectDocuments}
                  selectedDocuments={selectedDocuments}
                  exporting={documentsToExport.isFetching}
                  downloadDocuments={() => {
                    const allDocumentsInOrder = documents.all.flatMap(
                      (document) => {
                        if (document.id.includes('grouped')) {
                          const queries =
                            queryClient.getQueriesData<DocumentsResponse>([
                              'document',
                              'grouped',
                              documentsFilters,
                              document.id,
                            ]);

                          const query = queries?.find((q) => {
                            const [qk] = q;
                            return qk.includes(document.id);
                          });

                          if (!query) {
                            return document;
                          }

                          return query[1].payload.results;
                        }

                        return document;
                      }
                    );

                    const sortedSelectedDocuments = allDocumentsInOrder.filter(
                      (document) =>
                        selectedDocuments.map((d) => d.id).includes(document.id)
                    );

                    const selectedDocumentsToDownload =
                      sortedSelectedDocuments.slice(0, MAX_DOWNLOAD_LIMIT);

                    download(selectedDocumentsToDownload);
                  }}
                  exportingDisabled={documents.total === 0}
                  exportDocuments={documentsToExport.refetch}
                  dowloadingDisabled={selectedDocuments.length === 0}
                  sorting={
                    <WUI.AutoComplete
                      value={sorting}
                      options={sortingOptions}
                      className="documents__header-select"
                      onChange={(option) =>
                        option ? setSorting(option) : null
                      }
                    />
                  }
                  tabs={(document: Document) => (
                    <Tabs>
                      {document.linkedBulletinIds !== null &&
                      document.linkedBulletinIds.length !== 0 ? (
                        <Tab title="Update Note">
                          <UpdateNote ids={document.linkedBulletinIds} />
                        </Tab>
                      ) : (
                        <></>
                      )}
                      <Tab title="Document Content">
                        <DocumentContent
                          selectedEquipmentIds={
                            selectedAssignmentFilters.equipment
                              ?.map((e) => e.value)
                              .join(',') || ''
                          }
                          selectedInstallationIds={
                            selectedAssignmentFilters.installation
                              ?.map((i) => i.value)
                              .join(',') || ''
                          }
                          search={search}
                          document={document}
                        />
                      </Tab>
                      <Tab title="Assignments">
                        {document.hasAssignments ? (
                          <Assignments
                            documentId={document.id}
                            installation={
                              selectedAssignmentFilters.installation ?? []
                            }
                            networkCompany={
                              selectedAssignmentFilters.networkCompany ?? []
                            }
                            visibility={document.visibility}
                          />
                        ) : (
                          'The document has no assignments'
                        )}
                      </Tab>
                      <Tab title="Document Data">
                        <DocumentData data={document} />
                      </Tab>

                      <Tab title="Support & Feedback">
                        <Techrequest
                          techrequestAllowed={document.techrequestAllowed}
                          document={document}
                          documentsFilters={documentsFilters}
                        />
                      </Tab>
                    </Tabs>
                  )}
                  totalDocuments={documents.total}>
                  {documents.all}
                </DocumentsTable>
                {documents.hasNextPage ? (
                  <WUI.Button
                    ref={ref}
                    variant={WUI.ButtonVariant.Accent}
                    loading={documents.isFetchingNextPage}
                    onClick={() => documents.fetchNextPage()}>
                    Load more
                  </WUI.Button>
                ) : null}
              </>
            ) : null}
          </ErrorBoundary>
        </main>
      </div>
      <Footer>
        <div className="footer__support-container">
          <a
            target="_blank"
            rel="noreferrer"
            className="footer__support"
            href="https://wartsila.sharepoint.com/sites/INT-Technical-Knowledge-Base#need-support-or-got-a-good-idea">
            TKB SUPPORT
          </a>
          <MissingDocRequest
            link
            filters={documentsFilters}
            selectedAssignmentFilters={selectedAssignmentFilters}
            selectedDocumentTypes={selectedDocumentTypes}
          />
        </div>
        <div className="footer__license">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wartsila.sharepoint.com/sites/compass/Pages/Privacy-Notice.aspx">
            Employee privacy notice
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.wartsila.com/legal-privacy/privacy">
            Legal and privacy notice
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://dcac.wdm.wartsila.com/openfile.aspx?vault=2C278C26-ECD6-43AD-8D51-69151BE0294A&objtype=0&docid=390&fileid=4960&filever=-1">
            External resources privacy notice
          </a>
        </div>
      </Footer>

      <WUI.Modal open={infoModalOpened} onClose={toggleInfoModal}>
        <WUI.Modal.Header title="More information" onClose={toggleInfoModal} />
        <WUI.Modal.Content className="modal__content">
          <p>
            To search for articles, type in your search terms and/or specify
            additional filtering by customer/installation/equipment or document
            type.
          </p>

          <WUI.Table
            className="modal__table"
            data={[
              {
                'Search term': 'Cylinder liner',
                Result:
                  'articles with either word present, articles with both words are the better matches',
              },
              {
                'Search term': '”cylinder liner”',
                Result:
                  'articles with these words in this order next to each other',
              },
              {
                'Search term': 'cylinder line*',
                Result: 'either word present also matches; liner, liners, etc.',
              },
              {
                'Search term': 'cylinder and liner',
                Result: 'articles with both words present',
              },
              {
                'Search term': '2812* and liner',
                Result:
                  'documents where document id starts with 2812 and the document includes the word liner',
              },
              {
                'Search term': '2812V094GB',
                Result:
                  'documents where the document id is identical to the search string',
              },
              {
                'Search term': '”100 002”',
                Result: 'documents with the spare part number 100 002',
              },
            ]}
          />

          <h4>Search term examples:</h4>
          <p>
            When no search term is used, the results are shown with the newest
            article first. When search terms are used, the order is the
            relevance match. 20 articles are shown initially. Press the "Load
            more" button at the end of the list to get more articles.
          </p>
        </WUI.Modal.Content>
      </WUI.Modal>

      {filterTypes.isSuccess && subscriptionsOpened ? (
        <SubscriptionModal
          opened={subscriptionsOpened}
          onClose={toggleSubscriptions}
          title="Subscribe to notifications"
          filterRoles={filterTypes.data.roles}
          filterTypes={filterTypes.data.assignments}
          documentTypes={filterTypes.data.documentTypes}
        />
      ) : null}
    </>
  );
}
