import * as React from 'react';

import {
  InfiniteData,
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import UIKit, { ToastPosition, ToastVariant } from '@wartsila/ui-kit';

import find from 'lodash/find';
import flatMap from 'lodash/flatMapDeep';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import qs from 'qs';
import set from 'lodash/set';
import { queryClient } from '../..';
import { filterDictionary } from '../filters/filters.dictionary';
import { documentDictionary } from './documents.dictionary';
import { excelColumns, getExcelFile } from './documents.utils';
import { Document, DocumentsResponse } from './documents.types';
import {
  AdvancedSearchFilter,
  AssignmentsFilter,
  DocumentsFilter,
} from '../filters/filters.types';
import { useAuth } from '../auth/auth.hooks';
import { useDebounce } from '../filters/filters.utils';

type DocumentForExportParams = {
  limit?: number;
  filters: Partial<AssignmentsFilter>;
};
export const useDocumentsForExport = ({
  limit,
  filters,
}: DocumentForExportParams): UseQueryResult<Document[]> => {
  const { api } = useAuth();

  return useQuery(
    ['documents for export', limit, filters],
    () =>
      api
        .get<DocumentsResponse>(`/documents`, {
          params: {
            limit,
            ...filters,
          },

          paramsSerializer: (params) =>
            qs.stringify(params, {
              arrayFormat: 'comma',
              encodeValuesOnly: true,
            }),
        })
        .then(({ data }) => data.payload.results),
    {
      cacheTime: 0,
      staleTime: 0,
      enabled: false,
      refetchOnWindowFocus: false,

      onSuccess: (data) => {
        UIKit.showToast('Your browser will start downloading automatically', {
          timeout: 5000,
          variant: ToastVariant.Success,
          position: ToastPosition.BottomRight,
          title: `Exported ${data.length} documents!`,
        });

        if (data) {
          const filename = `documents_export`;

          getExcelFile({
            data: map(data, (entry) =>
              map(excelColumns, (column) =>
                column.col === 'mainType'
                  ? { t: 's', v: get(filterDictionary, get(entry, column.col)) }
                  : { t: 's', v: get(entry, column.col) }
              )
            ) as { t: string; v: string }[][],
            filename,
            sheet: filename,
            columns: data.some((col) => col.visibility === 'External')
              ? excelColumns
              : excelColumns.filter(
                  (header) => header.col !== 'wartsilaOnlineURL'
                ),
            dictionary: documentDictionary,
          });
        }
      },
      onError: () => {
        UIKit.showToast(`Unable to export documents`, {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        });
      },
    }
  );
};

export const useDocuments = ({
  filters,
  sortByField,
  limit = 15,
}: {
  limit?: number;
  sortByField: string;
  filters: Partial<AssignmentsFilter>;
}): UseInfiniteQueryResult<DocumentsResponse> & {
  total: number;
  all: Document[];
} => {
  const { accessToken, api } = useAuth();
  const [sortingField, sortOrder = ''] = sortByField.split('_');

  const query = useInfiniteQuery(
    ['documents', filters, sortByField],
    ({ pageParam = {} }) =>
      api
        .get<DocumentsResponse>(`/documents`, {
          params: {
            limit,
            sortOrder,
            offset: pageParam.offset,
            pagination: pageParam.pagination,
            sortByField: sortingField,
            ...filters,
          },
          paramsSerializer: (params) =>
            qs.stringify(params, {
              arrayFormat: 'comma',
              encodeValuesOnly: true,
            }),
        })
        .then(({ data }) => data),
    {
      // refresh cache after a minute
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: Boolean(accessToken),
      getNextPageParam: (currentPage, allPages) => {
        if (currentPage) {
          const offset = allPages.length * limit;
          const { totalNumberOfResults, pagination } = currentPage.payload;
          if (offset >= totalNumberOfResults) return null;
          return { offset, pagination: pagination || null };
        }

        return null;
      },
      onError: () =>
        UIKit.showToast('Fetching documents failed', {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        }),
    }
  );

  let all: Document[] = [];
  let documentsInTotal = 0;

  if (query.data) {
    all = flatMap(query.data.pages, (page) => page?.payload.results);

    const [firstPage] = query.data.pages;
    documentsInTotal = firstPage?.payload.totalNumberOfResults;
  }

  return { ...query, all, total: documentsInTotal };
};

type DocumentTypeFilter = { [key: string]: string[] };
type UseDocumentTypeFilters = {
  forImmediateAttention: boolean;
  documentTypes?: DocumentsFilter;
  selectedDocumentTypes: string[];
};

export const useDocumentTypeFilters = ({
  documentTypes = [],
  selectedDocumentTypes,
  forImmediateAttention,
}: UseDocumentTypeFilters): DocumentTypeFilter => {
  const documentTypeFilters: DocumentTypeFilter = {};

  forEach(selectedDocumentTypes, (selectedDocumentType) => {
    const isMainType = find(
      documentTypes,
      ({ value }) => value === selectedDocumentType
    );

    if (isMainType) {
      set(documentTypeFilters, selectedDocumentType, ['']);
      return;
    }

    forEach(documentTypes, (documentType) => {
      if ('children' in documentType) {
        forEach(documentType.children, (documentTypeChild) => {
          if (isEqual(documentTypeChild.value, selectedDocumentType)) {
            if (get(documentTypeFilters, documentType.value)) {
              get(documentTypeFilters, documentType.value).push(
                documentTypeChild.value
              );
            } else {
              set(documentTypeFilters, documentType.value, [
                documentTypeChild.value,
              ]);
            }
          }
        });
      }
    });
  });

  const BULLETIN_FILTER = 'ASI';
  const IMMEDIATE_ATTENTION_FILTER = 'For immediate attention';
  const bulletinFilters = get(documentTypeFilters, BULLETIN_FILTER);
  if (forImmediateAttention) {
    set(
      documentTypeFilters,
      BULLETIN_FILTER,
      (bulletinFilters || []).concat(IMMEDIATE_ATTENTION_FILTER)
    );
  } else {
    set(
      documentTypeFilters,
      BULLETIN_FILTER,
      (bulletinFilters || []).filter(
        (documentTypeFilter) =>
          documentTypeFilter !== IMMEDIATE_ATTENTION_FILTER
      )
    );
  }

  return documentTypeFilters;
};

export const useDocumentAdvancedSearch = (
  defaultFilters: Partial<AdvancedSearchFilter> = {}
): {
  advancedSearch: Partial<AdvancedSearchFilter>;
  setAdvancedSearch: (
    value: React.SetStateAction<Partial<AdvancedSearchFilter>>
  ) => void;
  debouncedAdvancedSearch: Partial<AdvancedSearchFilter>;
} => {
  const [advancedSearch, setAdvancedSearch] =
    React.useState<Partial<AdvancedSearchFilter>>(defaultFilters);

  // serviceProductCode stringified and added in App component, removed here
  // eslint-disable-next-line
  const { serviceProductCode, ...debouncedAdvancedSearch } =
    useDebounce(advancedSearch);

  return { advancedSearch, debouncedAdvancedSearch, setAdvancedSearch };
};

export const useDocumentSearch = (
  defaultSearch = ''
): {
  search: string;
  setSearch: (search: string) => void;
  debouncedSearch: string;
} => {
  const [search, setSearch] = React.useState(defaultSearch);
  const debouncedSearch = useDebounce(search);

  return { search, debouncedSearch, setSearch };
};

type UseBookmarked = {
  create: UseMutationResult<unknown, unknown, string, unknown>;
  remove: UseMutationResult<unknown, unknown, string, unknown>;
};

const mutateBookmarkStatusInQuery = (
  documentId: string,
  isBookmarked: boolean
): void => {
  const allDocuments = queryClient.getQueriesData<
    InfiniteData<DocumentsResponse>
  >(['documents']);

  const documentQueries = queryClient.getQueriesData<DocumentsResponse>([
    'document',
  ]);

  if (documentQueries.length) {
    forEach(documentQueries, (documentQuery) => {
      const [, response] = documentQuery;

      if (response?.payload) {
        forEach(response.payload.results, (document) => {
          if (document.id === documentId) {
            set(document, 'isBookmarked', isBookmarked);
          }
        });
      }
    });
  }

  if (allDocuments.length) {
    forEach(allDocuments, (documents) => {
      const [, { pages }] = documents;
      forEach(pages, (page) => {
        forEach(page.payload.results, (document) => {
          if (document.id === documentId) {
            set(document, 'isBookmarked', isBookmarked);
          }
        });
      });
    });
  }
};

export const useBookmark = (): UseBookmarked => {
  const { api } = useAuth();
  const create = useMutation(
    (documentId: string) =>
      api
        .post(`/documents/${documentId}/bookmark`, undefined)
        .then(({ data }) => data.payload),
    {
      onSuccess: (data, documentId) => {
        mutateBookmarkStatusInQuery(documentId, data.isBookmarked);

        UIKit.showToast('It will take few moments to update system.', {
          timeout: 5000,
          title: 'Your bookmark has been created!',
          variant: ToastVariant.Success,
          position: ToastPosition.BottomRight,
        });
      },
      onError: () => {
        UIKit.showToast('Creating bookmark failed', {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        });
      },
    }
  );

  const remove = useMutation(
    (documentId: string) =>
      api
        .delete(`/documents/${documentId}/bookmark`)
        .then(({ data }) => data.payload),
    {
      onSuccess: (data, documentId) => {
        mutateBookmarkStatusInQuery(documentId, data.isBookmarked);

        UIKit.showToast('It will take few moments to update system.', {
          timeout: 5000,
          title: 'Your bookmark has been removed!',
          variant: ToastVariant.Info,
          position: ToastPosition.BottomRight,
        });
      },

      onError: () => {
        UIKit.showToast('Deleting bookmark failed', {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        });
      },
    }
  );

  return { create, remove };
};

export const useDocument = (
  id: string,
  enabled: boolean,
  filters: { [key: string]: unknown } = {}
): UseQueryResult<DocumentsResponse> => {
  const { accessToken, api } = useAuth();

  return useQuery(
    id?.includes('grouped_')
      ? ['document', 'grouped', filters, id]
      : ['document', filters, id],
    () =>
      api
        .get(`/documents`, {
          params: {
            ...filters,
            id,
          },
          paramsSerializer: (params) =>
            qs.stringify(params, {
              arrayFormat: 'comma',
              encodeValuesOnly: true,
            }),
        })
        .then(({ data }) => data),
    {
      // refresh cache after a minute
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: Boolean(accessToken) && enabled,
      onError: () => {
        UIKit.showToast(`Fetching subdocuments for ${id} failed`, {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        });
      },
    }
  );
};

type UseTrackDocumentOptions = {
  enabled: boolean;
  language?: string;
};

export const useTrackDocument = (
  id: string,
  options: UseTrackDocumentOptions
): UseQueryResult<Document> => {
  const { accessToken, api } = useAuth();
  return useQuery(
    ['document tracking', id, options.language],
    () =>
      api
        .get(`/documents/${id}`, {
          params: {
            language: options.language,
          },
        })
        .then(({ data }) => data.payload),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: Boolean(accessToken) && options.enabled,
    }
  );
};

export const useTrackDocumentSearch = (): UseMutationResult<
  unknown,
  unknown,
  string,
  unknown
> => {
  const { api } = useAuth();
  return useMutation((search: string) =>
    api
      .get(`/documents`, {
        params: {
          search,
          isTextSearch: true,
        },
        paramsSerializer: (params) =>
          qs.stringify(params, {
            arrayFormat: 'comma',
            encodeValuesOnly: true,
          }),
      })
      .then(({ data }) => data)
  );
};
