import './styles.index.scss';
import { useIdleTimer } from 'react-idle-timer';

import * as React from 'react';

import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
  useMsal,
} from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from 'react-router-dom';
import UIKit, * as WUI from '@wartsila/ui-kit';
import axios, { AxiosInstance } from 'axios';

import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { datadogRum } from '@datadog/browser-rum';
import qs from 'qs';
import set from 'lodash/set';
import { useDefaultFilters } from './features/filters/filters.hooks';
import { useDocument } from './features/documents/documents.hooks';
import { HTMLContent } from './features/documentContent/documentContent.components';
import { File } from './features/file/file.types';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { DefaultFilters } from './features/filters/filters.types';
import { MissingDocRequest } from './features/docrequest/docrequest.component';

const Document = (): JSX.Element => {
  const location = useLocation();
  const paramString = location.search;
  const { id, fileId, link, search, equipment, installation, ...params } =
    qs.parse(paramString.slice(1, paramString.length));

  const documentResponse = useDocument(id as string, Boolean(id), {
    equipment,
    installation,
    ...params,
  } as Record<string, string>);

  const document = documentResponse.data?.payload.results[0];

  const showRequestDocumentButton =
    documentResponse.data?.payload.totalNumberOfResults === 0;

  const isDefault = (file: File): boolean => file.type === 'text/html';

  const isDefaultByLanguage = (file: File): boolean =>
    isDefault(file) && file.language === params.language;

  const isFallbackToEnglish = (file: File): boolean =>
    isDefault(file) && file.language.includes('en');

  const withFileId = (file: File): boolean =>
    isDefault(file) && file.id === fileId;

  let htmlFile = document?.files.find((file) =>
    fileId ? withFileId(file) : isDefaultByLanguage(file)
  );

  const isPDF = Boolean(link);

  if (!htmlFile) {
    htmlFile = document?.files.find(isFallbackToEnglish);
  }

  if (!htmlFile) {
    htmlFile = document?.files.find(isDefault);
  }

  const documentId = document?.id;

  if (documentResponse.isFetching) {
    return <WUI.Loader className="documentContent__loader" />;
  }

  if (htmlFile) {
    return (
      <HTMLContent
        file={htmlFile}
        onLoad={() => ({})}
        search={search as string}
        documentId={documentId || ''}
        installationIds={String(installation) || ''}
        equipmentIds={String(equipment) || ''}
      />
    );
  }

  if (isPDF) {
    return (
      <iframe title="pdf" width="100%" height="100%" src={link as string} />
    );
  }

  if (showRequestDocumentButton) {
    const queryParams = window.location.href.split('/document?')[1];
    return (
      <div className="missing-link-request">
        <b>Link target not found: </b>
        {queryParams}
        <div className="missing-link-request__button">
          <MissingDocRequest queryParams={queryParams} />
        </div>
      </div>
    );
  }

  return <div />;
};

export const queryClient = new QueryClient();

function AuthStatusScreen({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className="auth__screen">{children}</div>;
}

const AuthError = ({
  error,
}: React.PropsWithChildren<MsalAuthenticationResult>): JSX.Element => (
  <AuthStatusScreen>
    <div className="auth__error-title">An Error Occurred</div>

    {error?.errorMessage ? (
      <div className="auth__error-message">{error.errorMessage}</div>
    ) : null}
  </AuthStatusScreen>
);

const AuthLoading = (): JSX.Element => (
  <AuthStatusScreen>
    <WUI.Loader />
  </AuthStatusScreen>
);

const AuthBlacklisted = (): JSX.Element => (
  <AuthStatusScreen>
    <div>
      You don&apos;t have access to view this page. Please, contact
      administrator.
    </div>
  </AuthStatusScreen>
);

export type IAuthContext = {
  api: AxiosInstance;
  accessToken: string;
  account: AccountInfo;
  config: Record<string, unknown>;
};

export const AuthContext = React.createContext<IAuthContext>({
  api: axios.create(),
  accessToken: '',
  config: {},
  account: {
    name: '',
    username: '',
    tenantId: '',
    environment: '',
    homeAccountId: '',
    idTokenClaims: {},
    localAccountId: '',
  },
});

axios
  .get('/config.json')
  .then((res) => {
    const config = res.data;

    const authConfiguration: Configuration = {
      auth: {
        redirectUri: '/',
        clientId: config.REACT_APP_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${config.REACT_APP_TENANT_ID}`,
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    };

    const authInstance = new PublicClientApplication(authConfiguration);

    const AuthProvider = ({
      children,
    }: {
      children: React.ReactNode;
    }): JSX.Element => {
      const auth = useMsal();
      const [account] = auth.instance.getAllAccounts();
      const [relogonInitiated, setRelogonInitiated] = React.useState(false);
      const [rumInitiated, setRum] = React.useState(false);
      const [accessToken, setAccessToken] = React.useState('');
      const [blacklist, setBlacklist] = React.useState<{
        idle: boolean;
        allowed: boolean;
      }>({ allowed: false, idle: true });

      const getToken = (): Promise<AuthenticationResult> =>
        auth.instance
          .acquireTokenSilent({
            account,
            scopes: [`${config.REACT_APP_API_CLIENT_ID}/.default`],
          })
          .then((accountInformation) => {
            setAccessToken(accountInformation.accessToken);
            return accountInformation;
          });

      useIdleTimer({
        crossTab: true,
        syncTimers: 1000 * 15,
        timeout: 1000 * 60 * 60 * 3,
        onIdle: () => auth.instance.logout(),
      });

      const { search } = useLocation();
      const queryParams = React.useMemo(
        () => new URLSearchParams(search),
        [search]
      );

      const apiVersion = queryParams.get('api-version');

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      if (apiVersion) {
        set(headers, 'api-version', apiVersion);
      }

      React.useEffect(() => {
        if (relogonInitiated) {
          setTimeout(() => {
            window.location.href = '/';
          }, 6000);
          UIKit.showToast('You are being automatically logged-in.', {
            timeout: 5000,
            variant: WUI.ToastVariant.Info,
            title: 'Refresh',
            position: WUI.ToastPosition.BottomRight,
          });
        }
      }, [relogonInitiated]);

      const api = React.useMemo(() => {
        const axiosInstance = axios.create({
          headers,
        });

        axiosInstance.defaults.baseURL = `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}`;

        axiosInstance.interceptors.response.use(
          (response) => response,
          (error) => {
            if (error.response.status === 401) {
              setRelogonInitiated(true);
            }
            return error;
          }
        );
        return axiosInstance;
      }, [accessToken]);

      React.useEffect(() => {
        if (accessToken) {
          api
            .get('/contact')
            .then(({ data }) => {
              setBlacklist({ allowed: !data.payload.blacklisted, idle: false });
            })
            .catch(() => setBlacklist({ allowed: false, idle: false }));
        }
      }, [accessToken]);

      React.useEffect(() => {
        if (!rumInitiated) {
          const DATADOG_SITE = 'datadoghq.eu';
          const DATADOG_SERVICE = 'technical-knowledgebase';
          const DATADOG_PROXY_HOST = 'https://datadog-proxy.app.wartsila.com';
          const DATADOG_APP_ID = '8c7e06f7-528b-4cdc-9cb8-1677d52d8826';
          const DATADOG_CLIENT_TOKEN = 'pub0184df1c7255d9ae682ab3ac87165e9a';

          datadogRum.init({
            sampleRate: 100,
            site: DATADOG_SITE,
            trackInteractions: true,
            service: DATADOG_SERVICE,
            applicationId: DATADOG_APP_ID,
            proxyUrl: DATADOG_PROXY_HOST,
            clientToken: DATADOG_CLIENT_TOKEN,
            env: config.REACT_APP_ENVIRONMENT,
            version: config.REACT_APP_VERSION,
            allowedTracingOrigins: [config.REACT_APP_API_BASE_URL],
          });

          datadogRum.setUser({
            name: account.name,
            email: account.username,
          });

          setRum(true);
        }
      }, [rumInitiated, account]);

      React.useEffect(() => {
        if (!accessToken) getToken();
      }, [accessToken]);

      if (blacklist.idle) return <AuthLoading />;
      if (!blacklist.idle && !blacklist.allowed) return <AuthBlacklisted />;

      return (
        <AuthContext.Provider value={{ accessToken, account, config, api }}>
          {children}
        </AuthContext.Provider>
      );
    };

    const AppContainer = ({
      children,
    }: {
      children: React.ReactNode;
    }): JSX.Element => {
      const { defaultFilters, isLoading } = useDefaultFilters();
      if (isLoading) return <AuthLoading />;

      return (
        <>
          {React.Children.map(children, (child) =>
            React.isValidElement<{ defaultFilters: DefaultFilters }>(child)
              ? React.cloneElement(child, {
                  defaultFilters,
                })
              : child
          )}
        </>
      );
    };

    ReactDOM.render(
      <React.StrictMode>
        <MsalProvider instance={authInstance}>
          <MsalAuthenticationTemplate
            errorComponent={AuthError}
            loadingComponent={AuthLoading}
            interactionType={InteractionType.Redirect}>
            <QueryClientProvider client={queryClient}>
              <WUI.UIKitWrapper>
                <Router>
                  <AuthProvider>
                    <Switch>
                      <Route exact path="/">
                        <AppContainer>
                          <App />
                        </AppContainer>
                      </Route>
                      <Route path="/document/:documentNumber">
                        <App />
                      </Route>
                      <Route path="/document">
                        <div className="documentContent__cover">
                          <Document />
                        </div>
                      </Route>
                      <Route path="/pdf">
                        <div className="documentContent__cover">
                          <Document />
                        </div>
                      </Route>
                    </Switch>
                  </AuthProvider>
                </Router>
              </WUI.UIKitWrapper>
              {config.REACT_APP_ENVIRONMENT !== 'prod' && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            </QueryClientProvider>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );

    serviceWorker.unregister();
  })
  .catch();
