import { AssignmentDictionary } from './assignments.types';

export const assignmentDictionary: AssignmentDictionary = {
  id: 'Assignment Id',
  children: 'Equipment',
  networkCompany: 'S NWC',
  customerViews: 'Customer views',
  installationId: 'Installation number',
  installationName: 'Installation name',
  installationPartnerName: 'Installation Partner',
  applicationCategory: 'Application',
};
