import './TextArea.styles.scss';

import cx from 'classnames';

import * as WUI from '@wartsila/ui-kit';

export type TextAreaProps = React.ComponentPropsWithoutRef<'textarea'>;

export const TextArea = ({
  className,
  ...props
}: TextAreaProps): JSX.Element => {
  const classNames = cx('textarea', className);
  return <WUI.TextArea className={classNames} {...props} />;
};
