import './subscriptions.styles.scss';

import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import getFrom from 'lodash/get';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import map from 'lodash/map';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import set from 'lodash/set';
import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { MemoryRouter, NavLink, Redirect } from 'react-router-dom';

import * as WUI from '@wartsila/ui-kit';

import { Label } from '../../components/Label';
import {
  AssignmentFilters,
  DocumentFilters,
} from '../filters/filters.components';
import { filterDictionary } from '../filters/filters.dictionary';
import {
  AssignmentsFilter,
  AssignmentsFilterType,
  FilterValue,
} from '../filters/filters.types';
import { useSubscriptions } from './subscriptions.hooks';
import { Subscription, SubscriptionDetails } from './subscriptions.types';

const ContentPlaceholder = ({ rows = 7 }: { rows?: number }): JSX.Element => {
  const rowHeight = 60;

  return (
    <ContentLoader viewBox={`0 0 1500 ${rowHeight * rows}`}>
      {new Array(rows).fill(' ').map((_, index: number) => {
        const contentVerticalPosition = (contentHeight: number): number =>
          rows > 1 ? contentHeight + rowHeight * index : contentHeight;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <rect
              x="0"
              y={`${contentVerticalPosition(20)}`}
              rx="10"
              ry="4"
              width="730"
              height="40"
            />
            <rect
              x="760"
              y={`${contentVerticalPosition(20)}`}
              rx="10"
              ry="4"
              width="730"
              height="40"
            />
          </React.Fragment>
        );
      })}
    </ContentLoader>
  );
};

type SubscriptionModalProps = {
  title: string;
  opened: boolean;
  onClose: () => void;
  filterRoles: FilterValue[];
  documentTypes: FilterValue[];
  filterTypes: AssignmentsFilterType[];
};

const SubscriptionsList = ({
  data,
  loading,
  activeSubscriptionId,
  setActiveSubscriptionId,
}: {
  loading: boolean;
  data?: Subscription[] | null;
  activeSubscriptionId?: string;
  setActiveSubscriptionId: (id: string) => void;
}): JSX.Element => {
  if (loading)
    return (
      <ContentLoader viewBox="0 0 1500 60">
        <rect x="0" y="10" width="785" height="60" />
      </ContentLoader>
    );

  if (data && !data.length) return <div>No subscriptions found</div>;

  if (data) {
    const currentTab = data.findIndex((tab) => tab.id === activeSubscriptionId);
    return (
      <MemoryRouter>
        <WUI.Tabs
          currentTab={currentTab}
          className="subscriptions__tabs"
          onChange={(e) => {
            e.preventDefault();
            setActiveSubscriptionId((e.target as HTMLElement).id);
          }}>
          {data.map((subscription) => {
            const inactive = subscription.frequency === 'None';
            const classNames = inactive ? 'subscriptions__tab_inactive' : '';

            return (
              <NavLink
                id={subscription.id}
                key={subscription.id}
                className={classNames}
                to={`/${subscription.id}`}>
                {subscription.name || 'Unnamed'}
              </NavLink>
            );
          })}
        </WUI.Tabs>
        <Redirect to={`/${activeSubscriptionId}`} />
      </MemoryRouter>
    );
  }

  return <div />;
};

const frequencyOptions = ['Daily', 'Weekly', 'Monthly', 'None'].map((item) => ({
  value: item,
  label: item,
}));

const emptySubscription = {
  name: '',
  roles: [],
  customer: [],
  equipment: [],
  portfolio: [],
  mainTypes: [],
  installation: [],
  frequency: 'None',
  networkCompany: [],
  productReferenceType: [],
  productType: [],
  categories: ['Internal', 'External'],
};

export const SubscriptionModal = ({
  title,
  opened,
  onClose,
  filterRoles,
  filterTypes,
  documentTypes,
}: SubscriptionModalProps): JSX.Element => {
  const [subscriptionUpdates, setSubstriptionUpdates] = React.useState<
    Partial<SubscriptionDetails>
  >({});

  const [state, setState] = React.useState<{
    activeSubscriptionId?: string;
    selectedAssignmentFilters: Partial<AssignmentsFilter>;
  }>({
    selectedAssignmentFilters: {},
    activeSubscriptionId: undefined,
  });

  const { list, create, remove, get, edit } = useSubscriptions();
  const subscription = get(state.activeSubscriptionId);

  const hasUpdates = Boolean(Object.keys(subscriptionUpdates).length);

  const subscriptionDetails = {
    ...subscription.data,
    ...subscriptionUpdates,
  } as SubscriptionDetails;

  const resetUpdates = (): void => {
    setSubstriptionUpdates({});
  };

  const parseSubscriptionDetails = (
    sd: SubscriptionDetails
  ): SubscriptionDetails => {
    const parsedSubscription: SubscriptionDetails = sd;

    forEach(keys(sd), (field) => {
      const filterKey = field as keyof SubscriptionDetails;
      const filters: string | boolean | string[] | FilterValue[] =
        sd[filterKey];

      if (isArray(filters)) {
        const filtersArray = filters as string[] | FilterValue[];
        set(
          parsedSubscription,
          field,
          filtersArray.map((filter) =>
            typeof filter === 'string' ? filter : filter.value
          )
        );
      }
    });

    return parsedSubscription;
  };

  const setUpdates = (
    field: keyof SubscriptionDetails,
    update: unknown
  ): void => {
    setSubstriptionUpdates((s) => ({
      ...s,
      [field]: update,
    }));
  };

  const onSafeReset = (callback: () => void): void => {
    if (hasUpdates) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Changes will be lost. Continue?')) {
        resetUpdates();

        if (callback) callback();
      }
    } else if (callback) callback();
  };

  const selectCategory = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    const isBoth = event.target.value === 'Both';

    setUpdates(
      'categories',
      isBoth ? ['Internal', 'External'] : [event.target.value]
    );
  };

  const selectDocumentType = (selectedDocumentTypes: string[]): void => {
    setUpdates('mainTypes', selectedDocumentTypes);
  };

  const checkCategory = (category: string): boolean =>
    subscriptionDetails.categories?.toString() === category;

  const checkRole = (role: string): boolean =>
    Boolean(subscriptionDetails.roles?.includes(role));

  const selectRole = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    if (checked) {
      setUpdates('roles', subscriptionDetails.roles?.concat(name));
    } else {
      setUpdates(
        'roles',
        subscriptionDetails.roles?.filter((role) => role !== name)
      );
    }
  };

  React.useEffect(() => {
    if (subscription.isSuccess) {
      const savedFilters = {};

      forEach(
        keys(
          pick(subscription.data, [
            'customer',
            'equipment',
            'portfolio',
            'installation',
            'networkCompany',
            'productReferenceType',
            'productType',
          ])
        ),
        (key) => set(savedFilters, key, getFrom(subscription.data, key))
      );

      setState((s) => ({ ...s, selectedAssignmentFilters: savedFilters }));
    }
  }, [subscription.isSuccess, subscription.data]);

  React.useEffect(() => {
    if (list.isSuccess && !state.activeSubscriptionId) {
      const [firstSubscription] = list.data;
      setState({
        selectedAssignmentFilters: {},
        activeSubscriptionId: firstSubscription?.id,
      });
    }
  }, [list.isSuccess, list.data, state.activeSubscriptionId]);

  React.useEffect(() => {
    if (list.data && create.isSuccess) {
      const newSubscription = list.data[list.data.length - 1];
      setState({
        activeSubscriptionId: newSubscription?.id,
        selectedAssignmentFilters: {},
      });
    }
  }, [list.data, create.isSuccess]);

  React.useEffect(() => {
    if (subscription.data) {
      forEach(keys(subscriptionUpdates), (key) => {
        const filterKeyToUpdate = key as keyof SubscriptionDetails;

        let filterUpdate: string | boolean | string[] =
          subscription.data[filterKeyToUpdate];

        if (isArray(filterUpdate)) {
          filterUpdate = (
            subscription.data[filterKeyToUpdate] as FilterValue[] | string[]
          ).map((filter) =>
            typeof filter !== 'string' ? filter.value : filter
          );
        }

        const valuesAreEqual = isEqual(
          subscriptionUpdates[filterKeyToUpdate],
          filterUpdate
        );

        if (valuesAreEqual) {
          setSubstriptionUpdates(omit(subscriptionUpdates, filterKeyToUpdate));
        }
      });
    }
  }, [subscriptionUpdates, subscription.data]);

  React.useEffect(() => {
    if (edit.isSuccess) {
      subscription.refetch().then(resetUpdates);
    }
  }, [edit.isSuccess]);

  React.useEffect(() => {
    if (remove.isSuccess) {
      resetUpdates();

      setState({
        activeSubscriptionId: undefined,
        selectedAssignmentFilters: {},
      });
    }
  }, [remove.isSuccess]);

  React.useEffect(() => {
    forEach(keys(state.selectedAssignmentFilters), (key) =>
      setUpdates(
        key as keyof SubscriptionDetails,
        map(getFrom(state.selectedAssignmentFilters, key), ({ value }) => value)
      )
    );
  }, [state.selectedAssignmentFilters]);

  return (
    <WUI.Modal
      open={opened}
      onClose={() => onSafeReset(onClose)}
      className="subscriptions-modal">
      <WUI.Modal.Content>
        <div className="subscriptions__title">{title}</div>

        <div className="subscriptions__list">
          <SubscriptionsList
            data={list.data}
            loading={list.isFetching}
            activeSubscriptionId={state.activeSubscriptionId}
            setActiveSubscriptionId={(id) => {
              onSafeReset(() => {
                setState({
                  selectedAssignmentFilters: {},
                  activeSubscriptionId: id,
                });
              });
            }}
          />

          <WUI.Button
            loading={create.isLoading}
            variant={WUI.ButtonVariant.Outline}
            onClick={() => onSafeReset(() => create.mutate(emptySubscription))}>
            Add new
          </WUI.Button>
        </div>

        <div className="subscriptions__body">
          {subscription.isFetching ? <ContentPlaceholder /> : null}

          {subscription.data &&
          subscription.isSuccess &&
          subscriptionDetails ? (
            <>
              <div className="subscriptions__new">
                <div className="subscriptions__name">
                  <Label small title="Subscription name">
                    <WUI.InputField
                      value={subscriptionDetails.name || ''}
                      placeholder="Enter subscription name"
                      onChange={(event) => {
                        event.persist();
                        setUpdates('name', event.target.value);
                      }}
                    />
                  </Label>
                </div>
                <div className="subscriptions__frequency">
                  <Label small title="Frequency">
                    <WUI.AutoComplete
                      options={frequencyOptions}
                      value={{
                        value: subscriptionDetails.frequency,
                        label: subscriptionDetails.frequency,
                      }}
                      onChange={(selection) =>
                        // External component doesn't provide correct types
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setUpdates('frequency', selection?.value)
                      }
                    />
                  </Label>
                </div>
              </div>

              {filterRoles?.length ? (
                <div className="subscriptions__filters">
                  <div className="subscriptions__roles">
                    <div className="subscriptions__filters-title">
                      Role subscription
                    </div>

                    {filterRoles.map((filterRole) => (
                      <label
                        key={filterRole.value}
                        className="subscriptions__roles-input">
                        <input
                          type="checkbox"
                          onChange={selectRole}
                          name={filterRole.value}
                          checked={checkRole(filterRole.value)}
                        />
                        <span>{filterRole.label}</span>
                      </label>
                    ))}
                  </div>
                </div>
              ) : null}

              <div className="subscriptions__filters">
                <div>
                  <div className="subscriptions__filters-title">
                    Assignment filters
                  </div>
                  <AssignmentFilters
                    grid
                    open
                    hiddenTitle
                    filters={filterTypes}
                    preload={['portfolio']}
                    selected={state.selectedAssignmentFilters}
                    title={getFrom(filterDictionary, 'assignments')}
                    dependencies={{
                      installation: ['customer'],
                      productReferenceType: [
                        'customer',
                        'portfolio',
                        'equipment',
                        'installation',
                      ],
                      productType: ['productReferenceType'],
                      equipment: [
                        'customer',
                        'installation',
                        'portfolio',
                        'productReferenceType',
                      ],
                      portfolio: [
                        'customer',
                        'installation',
                        'productReferenceType',
                      ],
                    }}
                    select={(selection) => {
                      setState((s) => ({
                        ...s,
                        selectedAssignmentFilters: assign(
                          { ...s.selectedAssignmentFilters },
                          selection
                        ),
                      }));
                    }}
                  />
                </div>
                <div>
                  <div className="subscriptions__filters-title">
                    Choose document types to receive email notifications for
                  </div>
                  <DocumentFilters
                    open
                    hiddenTitle
                    hiddenSubtypes
                    filters={documentTypes}
                    showedImmediateAttention
                    select={selectDocumentType}
                    selected={subscriptionDetails.mainTypes || []}
                    title={getFrom(filterDictionary, 'documentTypes')}
                    forImmediateAttention={
                      subscriptionDetails.forImmediateAttention
                    }
                    toggleImmediateAttention={() =>
                      setUpdates(
                        'forImmediateAttention',
                        !subscriptionDetails.forImmediateAttention
                      )
                    }
                  />

                  <div>
                    <div className="subscriptions__filters-title">
                      Document category
                    </div>
                    <div className="subscriptions__categories">
                      <label>
                        <input
                          type="radio"
                          name="category"
                          value="External"
                          onChange={selectCategory}
                          checked={checkCategory('External')}
                        />
                        <span>External</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="category"
                          value="Internal"
                          onChange={selectCategory}
                          checked={checkCategory('Internal')}
                        />
                        <span>Internal</span>
                      </label>
                      <label>
                        <input
                          value="Both"
                          type="radio"
                          name="category"
                          onChange={selectCategory}
                          checked={checkCategory('Internal,External')}
                        />
                        <span>Both</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>

        <div className="subscriptions__footer">
          <div className="subscriptions__buttons">
            <WUI.Button
              variant={WUI.ButtonVariant.Grey}
              onClick={() => onSafeReset(onClose)}>
              Close
            </WUI.Button>
            {subscriptionDetails.frequency &&
            subscriptionDetails.frequency !== 'None' ? (
              <WUI.Button
                variant={WUI.ButtonVariant.Outline}
                onClick={() => setUpdates('frequency', 'None')}>
                Unsubscribe
              </WUI.Button>
            ) : null}
            {subscription.data && hasUpdates ? (
              <WUI.Button
                loading={edit.isLoading}
                onClick={() =>
                  edit.mutate(parseSubscriptionDetails(subscriptionDetails))
                }>
                Save
              </WUI.Button>
            ) : null}
          </div>

          {!subscription.isFetching && state.activeSubscriptionId ? (
            <WUI.Button
              loading={remove.isLoading}
              variant={WUI.ButtonVariant.Error}
              onClick={() =>
                // eslint-disable-next-line no-alert
                window.confirm('Delete subscription?')
                  ? // Typescript doesn't see that we have a check for activeSubscriptionId above
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    remove.mutate(state.activeSubscriptionId!)
                  : null
              }>
              Delete
            </WUI.Button>
          ) : null}
        </div>
      </WUI.Modal.Content>
    </WUI.Modal>
  );
};
