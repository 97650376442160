import UIKit, { ToastPosition, ToastVariant } from '@wartsila/ui-kit';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuth } from '../auth/auth.hooks';
import { DocumentsResponse, Document } from '../documents/documents.types';

export const useUpdateNote = (
  documentIds: string
): UseQueryResult<Document[]> => {
  const { api, accessToken } = useAuth();

  return useQuery(
    ['updateNote', documentIds],
    () =>
      api
        .get<DocumentsResponse>(`/documents`, {
          params: {
            id: documentIds,
          },
        })
        .then(({ data }) => data.payload.results),
    {
      enabled: Boolean(accessToken),
      onError: () => {
        UIKit.showToast('Fetching update notes failed', {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        });
      },
    }
  );
};
