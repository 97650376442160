import './Header.styles.scss';

import cx from 'classnames';

import { Logo } from '../Logo';

export type HeaderProps = {
  title: string;
  nav?: JSX.Element;
  className?: string;
  appEnvironment?: string;
};

export const Header = ({
  nav,
  title,
  className,
  appEnvironment,
}: HeaderProps): JSX.Element => {
  const classNames = cx('header', className);
  return (
    <header className={classNames}>
      <div className="header__meta">
        <Logo className="header__logo" />
        <div className="header__title">
          {title}{' '}
          {appEnvironment && appEnvironment !== 'prod'
            ? `/ ${appEnvironment.toLocaleUpperCase()}`
            : null}
        </div>
      </div>
      <div className="header__nav">{nav}</div>
    </header>
  );
};
