import './techrequest.styles.scss';

import axios from 'axios';
import qs from 'qs';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import UIKit, { ToastPosition, ToastVariant } from '@wartsila/ui-kit';

import { useAuth } from '../auth/auth.hooks';
import { APIResponse } from '../subscriptions/subscriptions.types';

export const useTechrequest = (
  request: string,
  documentId: string,
  documentsFilters: Record<string, string>
): UseQueryResult<string> => {
  const { accessToken, config } = useAuth();

  const chosenDocumentReferenceType = documentsFilters.productReferenceType
    ? documentsFilters.productReferenceType.split(',')
    : [];
  const chosenDocumentEquipment = documentsFilters.equipment
    ? documentsFilters.equipment.split(',')
    : [];

  // add query parameter prt/equipment if and only if there is a single filter value selected respectively
  const parameters = { subject: request };
  if (chosenDocumentReferenceType.length === 1) {
    Object.assign(parameters, { prt: chosenDocumentReferenceType[0] });
  }
  if (chosenDocumentEquipment.length === 1) {
    Object.assign(parameters, { equipment: chosenDocumentEquipment[0] });
  }

  return useQuery(
    ['techrequest', documentId],
    () =>
      axios
        .get<APIResponse<string>>(
          `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/documents/${documentId}/techrequest`,
          {
            params: parameters,
            paramsSerializer: (params) => qs.stringify(params),
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(({ data }) => data.payload),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        UIKit.showToast('Techrequest created', {
          timeout: 5000,
          title: 'Success!',
          variant: ToastVariant.Success,
          position: ToastPosition.BottomRight,
        });
      },
      onError: () => {
        UIKit.showToast('Fetching techrequest failed', {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        });
      },
    }
  );
};
