import './assignments.styles.scss';

import * as React from 'react';

import UIKit, * as WUI from '@wartsila/ui-kit';
import DataTable from 'react-data-table-component';
import { ReactComponent as MinusIconSVG } from '@wartsila/ui-kit/icons/box_min.svg';
import { ReactComponent as PlusIconSVG } from '@wartsila/ui-kit/icons/plus2.svg';
import get from 'lodash/get';
import map from 'lodash/map';
import { excelColumns, getExcelFile } from './assignments.utils';
import { useAssignments, useAssignmentsForExport } from './assignments.hook';

import { Assignment } from './assignments.types';
import { FilterAsyncMultiSelect } from '../filters/filters.components';
import { FilterValue } from '../filters/filters.types';
import { Label } from '../../components/Label';
import { assignmentDictionary } from './assignments.dictionary';
import { filterDictionary } from '../filters/filters.dictionary';

type AssignmentsProps = {
  documentId: string;
  installation: FilterValue[];
  networkCompany: FilterValue[];
  visibility: string;
};

type AssignmentsFiltersState = {
  id: FilterValue[];
  installation: FilterValue[];
  networkCompany: FilterValue[];
};

const expandableIconStyles = { width: 28, height: 28, fill: '#086795' };
const expandableIcon = {
  expanded: <MinusIconSVG style={expandableIconStyles} />,
  collapsed: <PlusIconSVG style={expandableIconStyles} />,
};

const dataTableCustomStyles = {
  table: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  rows: {
    style: {
      alignItems: 'start',

      padding: '0.5rem 0',

      borderRadius: 2,
      border: '1px solid black !important',
      marginBottom: '0.5rem',
    },
  },
  expanderRow: {
    style: {
      border: '1px solid black',
      padding: '1rem 1rem 1rem 3.5rem',
      transform: 'translateY(-0.6rem)',

      borderTopColor: 'transparent',
      borderBottomLeftRadius: 2,
      borderBottomRightRadius: 2,
    },
  },
  headCells: {
    style: {
      paddingLeft: '4px',
      paddingRight: '4px',
    },
  },
  cells: {
    style: {
      ':first-child': {
        alignSelf: 'center',
        marginLeft: '8px',
      },

      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};

const Cell = ({
  href,
  title,
  content,
}: {
  href?: string;
  title?: string;
  content?: string;
}): JSX.Element => (
  <div>
    <div className="assignments__cell-title">{title}</div>
    {href ? (
      <a href={href} target="_blank" rel="noreferrer">
        {content ?? '-'}
      </a>
    ) : (
      <div>{content ?? '-'}</div>
    )}
  </div>
);

const columnKeys = [
  { key: 'installationPartnerName', grow: 4, linkKey: 'customerURL' },
  { key: 'networkCompany', grow: 1 },
  { key: 'installationId', grow: 2 },
  { key: 'installationName', grow: 2 },
  { key: 'applicationCategory', grow: 1 },
  { key: 'customerViews', grow: 2, linkKey: 'viewReportURL' },
];

const customerViewsValuesWithoutLinks = [
  'Offline distribution',
  'No distribution',
];

const columns = columnKeys.map((column) => ({
  grow: column.grow,
  selector: column.key,
  name: get(assignmentDictionary, column.key),
  cell: (data: Assignment) => {
    const content = get(data, column.key);
    const title = get(assignmentDictionary, column.key);
    const isLinkApplicable =
      column.key !== 'customerViews' ||
      !customerViewsValuesWithoutLinks.includes(data.customerViews);
    const href =
      column.linkKey && isLinkApplicable
        ? get(data, column.linkKey)
        : undefined;
    return <Cell href={href} content={content} title={title} />;
  },
}));

const Table = ({ data }: { data?: Assignment }): JSX.Element => {
  const formatedData = data?.children.map((child) => ({
    Equipment: child.name,
    'Equipment operating status': child.status,
  }));

  return (
    <div className="assignments__equipment-list">
      <WUI.Table data={formatedData} />
    </div>
  );
};

export const Assignments = ({
  documentId,
  installation,
  networkCompany,
  visibility,
}: AssignmentsProps): JSX.Element => {
  const [summary, setSummary] = React.useState({
    isIdle: true,
    equipment: 0,
    isSuccess: false,
    installations: 0,
    viewCountInOnline: 0,
    viewCountInTKB: 0,
  });

  const [filters, setFilter] = React.useState<AssignmentsFiltersState>({
    installation,
    networkCompany,
    id: [{ value: documentId, label: 'id' }],
  });

  const assignments = useAssignments(documentId, {
    id: documentId,

    networkCompany: filters.networkCompany.map((n) => n.value).toString(),

    installation: filters.installation.map((i) => i.value).toString(),
  });

  const assignmentsForExport = useAssignmentsForExport(documentId, {
    id: documentId,

    networkCompany: filters.networkCompany.map((n) => n.value).toString(),

    installation: filters.installation.map((i) => i.value).toString(),
  });

  React.useEffect(() => {
    if (assignmentsForExport.isFetching) return;

    if (assignmentsForExport.isFetched) {
      if (assignmentsForExport.hasNextPage) {
        assignmentsForExport.fetchNextPage();
      } else {
        const flattenData = assignmentsForExport.all.map((e) => ({
          ...e,
          children: e.children.map((c) => c.name).toString(),
        }));

        const data = map(flattenData, (entry) =>
          map(excelColumns, (column) => entry[column.col])
        );

        const filename = `${documentId}_assignments_export`;

        getExcelFile({
          data,
          filename,
          sheet: filename,
          columns: excelColumns,
          dictionary: assignmentDictionary,
        });
      }
    }
  }, [assignmentsForExport.isFetching]);

  React.useEffect(() => {
    if (assignments.isSuccess && summary.isIdle) {
      const {
        totalNumberOfEquipment,
        totalNumberOfInstallations,
        viewCountInOnline,
        viewCountInTKB,
      } = assignments;

      setSummary({
        isIdle: false,
        isSuccess: true,
        equipment: totalNumberOfEquipment,
        installations: totalNumberOfInstallations,
        viewCountInOnline,
        viewCountInTKB,
      });
    }
  }, [assignments, summary.isIdle]);

  if (assignments.isError) {
    return <div />;
  }

  if (assignments.isFetching && summary.isIdle) {
    return <WUI.Loader className="assignments__loader" />;
  }

  return (
    <div className="assignments">
      <div className="assignments__title">
        {summary.isSuccess &&
        assignments.isSuccess &&
        !assignments.hasAssignmentsWithoutPartnerRole
          ? `The document is assigned to ${summary.equipment} equipment / ${summary.installations} installations.`
          : `This document is assigned to an account without an installation partner role.`}
      </div>
      <div>
        {summary.isSuccess &&
        assignments.isSuccess &&
        // VIEW COUNT: if internal, restricted or internal preview -> only show tkb views
        visibility === 'External'
          ? `This document has been viewed ${summary.viewCountInOnline} times in Wärtsilä Online and ${summary.viewCountInTKB} times in TKB.`
          : `This document has been viewed ${summary.viewCountInTKB} times in TKB.`}
      </div>

      <div className="assignments__filters-wrapper">
        <div className="assignments__filters">
          <div className="assignments__filter">
            <Label small title={get(filterDictionary, 'networkCompany')}>
              <FilterAsyncMultiSelect
                selection={filters}
                filterType="networkCompany"
                dependentOn={['id']}
                onSelect={(selection) =>
                  setFilter((state) => ({
                    ...state,
                    networkCompany: selection,
                  }))
                }
              />
            </Label>
          </div>
          <div className="assignments__filter">
            <Label small title={get(filterDictionary, 'installation')}>
              <FilterAsyncMultiSelect
                selection={filters}
                filterType="installation"
                dependentOn={['networkCompany', 'id']}
                onSelect={(selection) =>
                  setFilter((state) => ({
                    ...state,
                    installation: selection,
                  }))
                }
              />
            </Label>
          </div>
        </div>
        <WUI.Button
          loading={assignmentsForExport.isFetching}
          className="assignments__button"
          onClick={() => {
            assignmentsForExport.refetch();
            UIKit.showToast(
              'Your browser will start downloading automatically',
              {
                timeout: 7000,
                variant: WUI.ToastVariant.Success,
                position: WUI.ToastPosition.BottomRight,
                title: `Exporting started! It can take up to a minute.`,
              }
            );
          }}>
          Export to Excel
        </WUI.Button>
      </div>

      <div className="assignments__table">
        {assignments.isSuccess ? (
          <>
            <DataTable
              noHeader
              noTableHead
              expandableRows
              columns={columns}
              data={assignments.all}
              expandableIcon={expandableIcon}
              expandableRowsComponent={<Table />}
              customStyles={dataTableCustomStyles}
            />
            {assignments.hasNextPage ? (
              <WUI.Button
                variant={WUI.ButtonVariant.Accent}
                loading={assignments.isFetchingNextPage}
                onClick={() => assignments.fetchNextPage()}>
                Load more
              </WUI.Button>
            ) : null}
          </>
        ) : (
          <WUI.Loader className="assignments__loader" />
        )}
      </div>
    </div>
  );
};
