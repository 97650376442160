import './Accordion.styles.scss';

import cx from 'classnames';
import * as React from 'react';

import ArrowDownIconSVG from '@wartsila/ui-kit/icons/main_down.svg';
import ArrowUpIconSVG from '@wartsila/ui-kit/icons/main_up.svg';

import { Icon } from '../Icon';

export type AccordionProps = {
  grid?: boolean;
  title: string;
  open?: boolean;
  hiddenTitle?: boolean;
  badge?: JSX.Element;
  children: React.ReactNode;
};

export const Accordion = ({
  grid,
  open,
  title,
  badge,
  children,
  hiddenTitle,
}: AccordionProps): JSX.Element => {
  const [opened, setOpened] = React.useState(open);
  const toggle = (): void => setOpened((state) => !state);

  const tabClassNames = cx({
    accordion__tab: true,
    accordion__tab_active: opened,
  });

  const tabContent = cx({
    accordion__content: true,
    'accordion__content-grid': grid,
    'accordion__content_with-title': !hiddenTitle,
  });

  return (
    <div className="accordion">
      {!hiddenTitle ? (
        <div
          tabIndex={0}
          role="button"
          onClick={toggle}
          onKeyPress={toggle}
          className={tabClassNames}>
          <span className="accordion__title">
            <Icon
              alt=""
              className="accordion__arrow"
              src={opened ? ArrowUpIconSVG : ArrowDownIconSVG}
            />
            {title}
          </span>
          {badge}
        </div>
      ) : null}
      {opened && <div className={tabContent}>{children}</div>}
    </div>
  );
};
