import * as React from 'react';

const ACTION_ADD = 'add';
const ACTION_REMOVE = 'remove';

const DARK_THEME = 'dark';
const LOCAL_STORAGE_KEY = 'tkb-selected-dark-theme';

type ReturnType = { readonly toggle: () => void; isDark: boolean };

export const useTheme = (): ReturnType => {
  const [enabled, set] = React.useState<boolean>(() => {
    const isDarkThemeSelected = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    return isDarkThemeSelected ? JSON.parse(isDarkThemeSelected) : false;
  });

  React.useEffect(() => {
    const action = enabled ? ACTION_ADD : ACTION_REMOVE;
    window.document.body.classList[action](DARK_THEME);
  }, [enabled]);

  const toggle = React.useCallback(() => {
    set((state) => {
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(!state));
      return !state;
    });
  }, []);

  return { toggle, isDark: enabled } as const;
};
