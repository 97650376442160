import flatMap from 'lodash/flatMap';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';

import UIKit, { ToastPosition, ToastVariant } from '@wartsila/ui-kit';

import { useAuth } from '../auth/auth.hooks';
import { Assignment, AssignmentsResponse } from './assignments.types';

export const useAssignmentsForExport = (
  documentId: string,
  params?: Record<string, string>
): UseInfiniteQueryResult<AssignmentsResponse> & {
  all: Assignment[];
  totalNumberOfEquipment: number;
  totalNumberOfInstallations: number;
} => {
  const { api } = useAuth();

  const query = useInfiniteQuery(
    ['assignments for export', `document/${documentId}`, params],
    ({ pageParam = '' }) =>
      api
        .get<AssignmentsResponse>(`/documents/${documentId}/assignments`, {
          params: {
            ...params,
            id: documentId,
            cursor: pageParam,
          },
        })
        .then(({ data }) => data),

    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: false,
      getNextPageParam: (currentPage) => currentPage.payload.cursor,
      onError: () =>
        UIKit.showToast('Fetching assignments failed', {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        }),
    }
  );

  const all: Assignment[] = query.data
    ? flatMap(query.data.pages, (page) => page.payload.results)
    : [];

  let totalNumberOfEquipment = 0;
  let totalNumberOfInstallations = 0;

  if (query.data) {
    const [firstPage] = query.data.pages;
    totalNumberOfEquipment = firstPage.payload.totalNumberOfEquipment;
    totalNumberOfInstallations = firstPage.payload.totalNumberOfInstallations;
  }

  return { ...query, all, totalNumberOfEquipment, totalNumberOfInstallations };
};

export const useAssignments = (
  documentId: string,
  params?: Record<string, string>
): UseInfiniteQueryResult<AssignmentsResponse> & {
  all: Assignment[];
  totalNumberOfEquipment: number;
  totalNumberOfInstallations: number;
  viewCountInOnline: number;
  viewCountInTKB: number;
  hasAssignmentsWithoutPartnerRole: boolean;
} => {
  const { api, accessToken } = useAuth();

  const query = useInfiniteQuery(
    ['assignments', `document/${documentId}`, params],
    ({ pageParam = '' }) =>
      api
        .get<AssignmentsResponse>(`/documents/${documentId}/assignments`, {
          params: {
            ...params,
            cursor: pageParam,
          },
        })
        .then(({ data }) => data),

    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: Boolean(accessToken),
      getNextPageParam: (currentPage) => currentPage.payload.cursor,
      onError: () =>
        UIKit.showToast('Fetching assignments failed', {
          title: 'Error!',
          variant: ToastVariant.Warning,
          position: ToastPosition.BottomRight,
        }),
    }
  );

  const all: Assignment[] = query.data
    ? flatMap(query.data.pages, (page) => page.payload.results)
    : [];

  let totalNumberOfEquipment = 0;
  let totalNumberOfInstallations = 0;
  let viewCountInOnline = 0;
  let viewCountInTKB = 0;
  let hasAssignmentsWithoutPartnerRole = false;

  if (query.data) {
    const [firstPage] = query.data.pages;
    totalNumberOfEquipment = firstPage.payload.totalNumberOfEquipment;
    totalNumberOfInstallations = firstPage.payload.totalNumberOfInstallations;
    viewCountInOnline = firstPage.payload.viewCountInOnline;
    viewCountInTKB = firstPage.payload.viewCountInTKB;
    hasAssignmentsWithoutPartnerRole =
      firstPage.payload.hasAssignmentsWithoutPartnerRole;
  }

  return {
    ...query,
    all,
    totalNumberOfEquipment,
    totalNumberOfInstallations,
    viewCountInOnline,
    viewCountInTKB,
    hasAssignmentsWithoutPartnerRole,
  };
};
