import './Label.styles.scss';

import cx from 'classnames';
import { set } from 'lodash';
import * as React from 'react';

type LabelSize = {
  title: string;
  small?: boolean;
  icon?: JSX.Element;
};

export type LabelProps = React.ComponentPropsWithoutRef<'label'> & LabelSize;

export const Label = ({
  className,
  children,
  title,
  small,
  icon,
  ...props
}: LabelProps): JSX.Element => {
  const labelClassNames = cx('label', className);

  const titleClassNames = cx({
    label__title: true,
    label__title_small: small,
  });

  const customProps = set({ ...props }, 'id', title);

  return (
    <>
      <span className={titleClassNames}>
        <label className={labelClassNames} htmlFor={title} {...props}>
          {title}
        </label>
        {icon}
      </span>
      {React.Children.map<React.ReactNode, React.ReactNode>(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, customProps)
          : child
      )}
    </>
  );
};
