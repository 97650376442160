import get from 'lodash/get';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { documentDictionary } from '../documents/documents.dictionary';
import { Document } from '../documents/documents.types';
import { filterDictionary } from '../filters/filters.dictionary';
import { useUpdateNote } from './updateNote.hooks';
import './updateNote.styles.scss';

const updateNoteTitle = 'Note!';
const updateNoteInfoMessage =
  'Some instructions in this chapter have been superseded by revised instructions in the following service bulletin(s):';

const Cell = ({
  style,
  content,
}: {
  content?: string;
  style?: React.CSSProperties;
}): JSX.Element | null => (
  <div className="document__cell" style={style}>
    <div
      className="document__cell-content"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content || '-' }}
    />
  </div>
);

const columnKeys = [
  { key: 'documentNumber', grow: 2 },
  { key: 'title', grow: 3 },
  { key: 'mainType', grow: 2 },
  { key: 'subType', grow: 3 },
  { key: 'visibility', grow: 1 },
  { key: 'documentDate', grow: 1 },
];

const columns = columnKeys.map((column) => ({
  grow: column.grow,
  selector: column.key,
  name: get(documentDictionary, column.key),
  cell: (data: Document) => {
    let content = get(data, column.key);

    if (column.key === 'documentNumber') {
      return (
        <Cell
          content={`<a href=${window.location.origin}?doc=${content} target="_blank" rel="noopener noreferrer">${content}</a>`}
        />
      );
    }

    if (column.key === 'documentDate') {
      content = moment(content).format('DD.MM.YYYY');
    }

    if (column.key === 'mainType') {
      content = get(filterDictionary, content, content);
    }

    return (
      <Cell content={Array.isArray(content) ? content.toString() : content} />
    );
  },
}));

export const UpdateNote = ({ ids }: { ids: string[] }): JSX.Element | null => {
  const documents = useUpdateNote(String(ids));

  if (documents.isFetching) return <div>Loading...</div>;
  if (documents.isError) return <div>Error when fetching documents</div>;

  if (documents.isSuccess) {
    return (
      <div className="update-note-header">
        <div className="update-note-header__title">{updateNoteTitle}</div>
        <div>{updateNoteInfoMessage}</div>
        <div>
          <DataTable noHeader columns={columns} data={documents.data} />
        </div>
      </div>
    );
  }

  return null;
};
