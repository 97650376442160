import { AxiosError } from 'axios';
import getFrom from 'lodash/get';
import keys from 'lodash/keys';
import set from 'lodash/set';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import UIKit, { ToastPosition, ToastVariant } from '@wartsila/ui-kit';

import { queryClient } from '../..';
import { useAuth } from '../auth/auth.hooks';
import { FilterType, FilterValue } from '../filters/filters.types';
import {
  APIResponse,
  Subscription,
  SubscriptionDetails,
} from './subscriptions.types';

type FrequencyParams = {
  subscriptionId: string;
  frequency: 'None' | 'Daily' | 'Monthly';
};

type SubscriptionDetailsMutation = UseMutationResult<
  Partial<SubscriptionDetails>,
  AxiosError<unknown>,
  Partial<SubscriptionDetails>,
  unknown
>;

type UseSubscriptionsProps = {
  get: (id?: string) => UseQueryResult<SubscriptionDetails>;
  edit: SubscriptionDetailsMutation;
  create: SubscriptionDetailsMutation;
  list: UseQueryResult<Subscription[]>;
  remove: UseMutationResult<unknown, AxiosError<unknown>, string>;
  setFrequency: UseMutationResult<
    FrequencyParams,
    AxiosError<unknown>,
    FrequencyParams
  >;
};

const showErrorMessage = (message: string): Promise<unknown> =>
  UIKit.showToast(message, {
    title: 'Error!',
    variant: ToastVariant.Warning,
    position: ToastPosition.BottomRight,
  });

const showInfoMessage = (title: string, message: string): Promise<unknown> =>
  UIKit.showToast(message, {
    title,
    timeout: 5000,
    variant: ToastVariant.Info,
    position: ToastPosition.BottomRight,
  });

const showSuccessMessage = (title: string, message: string): Promise<unknown> =>
  UIKit.showToast(message, {
    title,
    timeout: 5000,
    variant: ToastVariant.Success,
    position: ToastPosition.BottomRight,
  });

export const useSubscriptions = (): UseSubscriptionsProps => {
  const { accessToken, api } = useAuth();

  const list = useQuery(
    ['subscriptions'],
    () =>
      api
        .get<APIResponse<Subscription[]>>(`/subscriptions`)
        .then(({ data }) => data.payload),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(accessToken),
      onError: () => {
        showErrorMessage('Fetching subscriptions list failed');
      },
    }
  );

  const fetchFiltersLabels = async (
    filterType: string,
    filtersToQuery: string[]
  ): Promise<FilterValue[]> => {
    let filtersWithLabels: FilterValue[] = [];
    for (
      let filterIndex = 0;
      filterIndex < filtersToQuery.length;
      // eslint-disable-next-line no-plusplus
      filterIndex++
    ) {
      const filterToQuery = filtersToQuery[filterIndex];

      filtersWithLabels = filtersWithLabels.concat(
        // eslint-disable-next-line no-await-in-loop
        await api
          .get(`/filters/${filterType}/search/${filterToQuery}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(
            ({ data }) => {
              if (data.payload.length) return data.payload;
              // Return default value if label not found
              return [{ value: filterToQuery, label: filterToQuery }];
            },
            // Return default value if error
            () => [{ value: filterToQuery, label: filterToQuery }]
          )
      );
    }

    return filtersWithLabels;
  };

  const fetchFilters = async (
    subsciption: SubscriptionDetails
  ): Promise<{
    [key: string]: FilterValue[];
  }> => {
    const assignmentFilters: { [key: string]: FilterValue[] } = {};
    const filterTypes = keys(subsciption).filter((key) =>
      [
        'customer',
        'equipment',
        'portfolio',
        'installation',
        'networkCompany',
        'productReferenceType',
        'productType',
      ].includes(key)
    ) as FilterType[];

    for (
      let filterTypeIndex = 0;
      filterTypeIndex < filterTypes.length;
      // eslint-disable-next-line no-plusplus
      filterTypeIndex++
    ) {
      const filterType = filterTypes[filterTypeIndex];

      // eslint-disable-next-line no-await-in-loop
      const filtersLabels = await fetchFiltersLabels(
        filterType,
        getFrom(subsciption, filterType) as string[]
      );

      set(assignmentFilters, filterType, filtersLabels);
    }

    return assignmentFilters;
  };

  const get = (subscriptionId?: string): UseQueryResult<SubscriptionDetails> =>
    useQuery(
      ['subscription', subscriptionId],
      () =>
        api
          .get<APIResponse<SubscriptionDetails>>(
            `/subscriptions/${subscriptionId}`
          )
          .then(async ({ data }) => {
            const filtersWithLabels = await fetchFilters(data.payload);
            return { ...data.payload, ...filtersWithLabels };
          }),

      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled: Boolean(subscriptionId),
        onError: () =>
          showErrorMessage(`Fetching subscription ${subscriptionId} failed`),
      }
    );

  const create = useMutation<
    Partial<SubscriptionDetails>,
    AxiosError,
    Partial<SubscriptionDetails>
  >(
    (subscription: Partial<SubscriptionDetails>) =>
      api
        .post<APIResponse<SubscriptionDetails>>(`/subscriptions`, subscription)
        .then(({ data }) => data.payload),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['subscriptions']);
      },
      onError: () => {
        showErrorMessage('Creating subscription failed');
      },
    }
  );

  const edit = useMutation<
    Partial<SubscriptionDetails>,
    AxiosError,
    Partial<SubscriptionDetails>
  >(
    ({ id, ...subscription }) =>
      api
        .post<APIResponse<SubscriptionDetails>>(
          `/subscriptions/${id}`,
          subscription
        )
        .then(({ data }) => data.payload),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(['subscriptions']);
        showSuccessMessage(
          data.name || 'Success!',
          'Changes to subsciption filters have been saved.'
        );
      },
      onError: () => {
        showErrorMessage('Saving subscription failed');
      },
    }
  );

  const remove = useMutation<unknown, AxiosError<unknown>, string>(
    (subscriptionId: string) => api.delete(`/subscriptions/${subscriptionId}`),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['subscriptions']);
        showInfoMessage('Success', 'Subscription deleted.');
      },
      onError: () => {
        showErrorMessage('Removing subscription failed');
      },
    }
  );

  const setFrequency = useMutation<
    FrequencyParams,
    AxiosError<unknown>,
    FrequencyParams
  >(
    ({ subscriptionId, frequency }: FrequencyParams) =>
      api
        .post(`/subscriptions/${subscriptionId}`, { frequency })
        .then(({ data }) => data.payload),
    {
      onError: () => {
        showErrorMessage('Changing frequency failed');
      },
    }
  );

  return { list, create, edit, remove, get, setFrequency };
};
