import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './CheckboxTree.styles.scss';

import assign from 'lodash/assign';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import * as React from 'react';
import ReactCheckboxTree, { Node } from 'react-checkbox-tree';

import { ReactComponent as ArrowDownIconSVG } from '@wartsila/ui-kit/icons/main_down.svg';
import { ReactComponent as ArrowUpIconSVG } from '@wartsila/ui-kit/icons/main_up.svg';

type CheckboxTreeProps = {
  nodes?: Node[];
  checked?: string[];
  hiddenSubtypes?: boolean;
  onChange?: (checked: string[]) => void;
};

type CheckboxTreeState = {
  checked: string[];
  expanded: string[];
};

type StateUpdate = Partial<{
  [key in keyof CheckboxTreeState]: string[];
}>;

const icons = {
  expandOpen: <ArrowUpIconSVG />,
  expandClose: <ArrowDownIconSVG />,
};

// Storing expanded nodes in memory otherwise,
// all nodes will be closed by default when the component mounts to the DOM again
let expanded: string[] = [];

export const CheckboxTree = ({
  hiddenSubtypes,
  checked = [],
  nodes = [],
  onChange,
}: CheckboxTreeProps): JSX.Element => {
  const [state, setState] = React.useState<CheckboxTreeState>({
    checked: [],
    expanded,
  });

  const updateState = React.useCallback(
    (update: StateUpdate): void =>
      setState((currentState) => assign({ ...currentState }, update)),
    []
  );

  React.useEffect(() => {
    if (onChange) {
      onChange(state.checked);
    }
  }, [state.checked]);

  React.useEffect(() => {
    if (isEqual(state.checked, checked)) return;
    updateState({ checked });
  }, []);

  return (
    <ReactCheckboxTree
      nodes={
        hiddenSubtypes ? nodes.map((node) => omit(node, 'children')) : nodes
      }
      icons={icons}
      nativeCheckboxes
      showNodeIcon={false}
      checked={state.checked}
      expanded={state.expanded}
      onCheck={(checkedNodes) => updateState({ checked: checkedNodes })}
      onExpand={(expandedNodes) => {
        updateState({ expanded: expandedNodes });
        expanded = expandedNodes;
      }}
    />
  );
};
