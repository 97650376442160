import './Badge.styles.scss';

import * as WUI from '@wartsila/ui-kit';

export type BadgeProps = React.ComponentProps<typeof WUI.Badge>;

export const Badge = ({
  children,
  ...props
}: BadgeProps): JSX.Element | null => {
  if (!children || children === '0') return null;

  return <WUI.Badge {...props}>{children}</WUI.Badge>;
};
