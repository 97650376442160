import './Tabs.styles.scss';

import findIndex from 'lodash/findIndex';
import * as React from 'react';

import * as WUI from '@wartsila/ui-kit';

type TabProps = {
  // The title used in the parent component
  // eslint-disable-next-line react/no-unused-prop-types
  title: string;

  isActive?: boolean;
  children?: React.ReactNode;
};

export const Tab = ({
  children,
  isActive = false,
}: TabProps): JSX.Element | null => (isActive ? <>{children}</> : null);

export const Tabs = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}): JSX.Element => {
  const tabs = React.Children.map(
    children,
    (child: React.ReactElement<TabProps>) => child.props.title
  );

  const [selectedTab, selectTab] = React.useState(tabs[0]);

  const childrenWithProps = React.Children.map(
    children,
    (child: React.ReactElement<TabProps>) =>
      React.isValidElement(child)
        ? React.cloneElement(child, {
            isActive: child.props.title === selectedTab,
          })
        : child
  );

  return (
    <>
      <div className="tabs">
        <WUI.Tabs
          currentTab={findIndex(tabs, (tab: string) => tab === selectedTab)}>
          {tabs.map((tab) => (
            <button
              key={tab}
              type="button"
              // onClick doesn't work here
              // it seems WUI.Tabs is changing onClick behavior
              onKeyDown={() => selectTab(tab)}
              onMouseDown={() => selectTab(tab)}>
              {tab}
            </button>
          ))}
        </WUI.Tabs>
      </div>
      {childrenWithProps}
    </>
  );
};
