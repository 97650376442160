import './Icon.styles.scss';

import cx from 'classnames';

type AccessibleClickEvent =
  | React.MouseEvent<HTMLButtonElement>
  | React.KeyboardEvent<HTMLButtonElement>;

type IconProps = {
  large?: boolean;
  className?: string;
  onClick?: (event: AccessibleClickEvent) => void;
};

type IconImageProps = IconProps &
  (
    | {
        src: string;
        alt: string;
      }
    | { component: JSX.Element }
  );

export const Icon = ({
  large,
  onClick,
  className,
  ...props
}: IconImageProps): JSX.Element => {
  const classNames = cx(
    {
      'internal-icon': true,
      'internal-icon_large': large,
      'internal-icon_pointer': Boolean(onClick),
      'internal-icon__component': 'component' in props,
    },
    className
  );

  const handleClick = (event: AccessibleClickEvent): void => {
    event.preventDefault();
    if (onClick) onClick(event);
  };

  if ('src' in props) {
    const { alt, src, ...restProps } = props;
    if (onClick) {
      return (
        <button type="button" onClick={handleClick} onKeyPress={handleClick}>
          <img src={src} alt={alt} className={classNames} {...restProps} />
        </button>
      );
    }

    return <img src={src} alt={alt} className={classNames} {...restProps} />;
  }

  if (onClick) {
    return (
      <button
        type="button"
        onClick={handleClick}
        className={classNames}
        onKeyPress={handleClick}>
        {props.component}
      </button>
    );
  }

  return <span className={classNames}>{props.component}</span>;
};
