import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useAuth } from '../features/auth/auth.hooks';
import { APIResponse } from '../features/subscriptions/subscriptions.types';

type Contact = {
  blacklisted: boolean;
  emailSubscriptionDisabled: boolean;
};

export const useContact = (): UseQueryResult<Contact> => {
  const { accessToken, api } = useAuth();

  return useQuery(
    ['contact'],
    () =>
      api
        .get<APIResponse<Contact>>(`/contact`)
        .then(({ data }) => data.payload),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(accessToken),
    }
  );
};
