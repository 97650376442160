import * as React from 'react';
import AutosizeInput from 'react-input-autosize';

import * as WUI from '@wartsila/ui-kit';

import { queryClient } from '../..';
import { Label } from '../../components/Label';
import { Document } from '../documents/documents.types';
import { useTechrequest } from './techrequest.hooks';

const stripHTML = (html: string): string => html.replace(/(<([^>]+)>)/gi, '');

export const Techrequest = ({
  techrequestAllowed,
  document,
  documentsFilters,
}: {
  techrequestAllowed: boolean;
  document: Document;
  documentsFilters: Record<string, string>;
}): JSX.Element => {
  const [request, setRequest] = React.useState(
    `${document.documentNumber} - ${stripHTML(document.title)}`
  );

  const techrequest = useTechrequest(request, document.id, documentsFilters);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setRequest(value);
  };

  React.useEffect(() => {
    if (techrequest.isSuccess) {
      window.open(techrequest.data, '_blank');
      queryClient.resetQueries(['techrequest']);
    }
  }, [techrequest.isSuccess, techrequest.data]);

  return (
    <>
      {techrequestAllowed ? (
        <div className="techrequest__form">
          <div>
            In case of technical questions on the content, please create a
            TechRequest:
          </div>
          <div>
            <Label title="Subject" small>
              <AutosizeInput
                value={request}
                onChange={onChange}
                disabled={techrequest.isFetching}
                placeholder="Document Number - Document title"
              />
            </Label>
          </div>
          <WUI.Button
            loading={techrequest.isFetching}
            onClick={() => techrequest.refetch()}>
            Create TechRequest
          </WUI.Button>
        </div>
      ) : (
        <></>
      )}
      <div className="techrequest_paragraph">
        For support, feedback or enhancement proposals related to the TKB
        functionality,
        <br /> please primarily use the Viva Engage TKB Group to share the
        information.
      </div>
      <WUI.Button
        onClick={() =>
          window.open(
            'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI3NDI3ODMxIn0',
            '_blank'
          )
        }>
        Open Viva Engage
      </WUI.Button>
    </>
  );
};
