/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import './documentContent.styles.scss';

import * as React from 'react';
import * as WUI from '@wartsila/ui-kit';

import { ReactComponent as DocIconSVG } from '@wartsila/ui-kit/icons/doc.svg';
import { ReactComponent as HTMLIconSVG } from '@wartsila/ui-kit/icons/doc_HTML.svg';
import { OptionTypeBase } from 'react-select';
import { ReactComponent as PDFIconSVG } from '@wartsila/ui-kit/icons/doc_PDF.svg';
import { UseQueryResult } from '@tanstack/react-query';
import { ReactComponent as VideoIconSVG } from '@wartsila/ui-kit/icons/video.svg';
import cx from 'classnames';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import qs from 'qs';
import uniqWith from 'lodash/uniqWith';
import { useLocation } from 'react-router-dom';
import { documentContentDictionary } from './documentContent.dictionary';
import { File } from '../file/file.types';
import { Document } from '../documents/documents.types';
import {
  useHTML,
  useQueueDownload,
  useRawHTML,
  useResources,
  useTransferStatus,
} from '../file/file.hooks';
import { useTrackDocument } from '../documents/documents.hooks';

const highlight = ({
  keyword = '',
  content = '',
}: {
  keyword?: string;
  content?: string;
}): string => {
  if (!keyword) return content;
  // check for trailing * and replace with empty string
  const curatedKeyword = keyword.replace(/^\*/, '');

  const matches = Array.from(
    content.matchAll(new RegExp(`\\b${curatedKeyword}`, 'gi'))
  ).map((x) => x[0]);
  const uniqMatches = matches.filter(
    (value, index) => matches.indexOf(value) === index
  );
  let markedContent = content;
  uniqMatches.forEach((match) => {
    markedContent = markedContent.replace(
      new RegExp(`(?![^<]*>)\\b${match}`, 'gi'),
      `<mark>${match}</mark>`
    );
  });
  return markedContent;
};

const Content = ({
  file,
  onLoad,
  documentId,
  contentType,
}: {
  file: File;
  documentId: string;
  contentType: string;
  onLoad: (link: string) => void;
}): JSX.Element | null => {
  const [downloadState, setDownloadState] = React.useState({
    downloadUrl: '',
    downloadReady: false,
    refetchInterval: 1000,
  });

  const queueDownload = useQueueDownload({ documentId, file });
  const transferStatus = useTransferStatus(
    queueDownload?.data?.id,
    downloadState.refetchInterval
  );

  React.useEffect(() => {
    if (queueDownload.isFetching) {
      setDownloadState({
        downloadUrl: '',
        downloadReady: false,
        refetchInterval: 1000,
      });
    }
  }, [queueDownload.isFetching]);

  React.useEffect(() => {
    if (queueDownload.isSuccess) {
      if (queueDownload.data?.url) {
        setDownloadState((state) => ({
          ...state,
          downloadUrl: queueDownload.data.url,
        }));
      }
    }
  }, [queueDownload.isSuccess, queueDownload.data]);

  React.useEffect(() => {
    if (transferStatus.isError) {
      // Otherwise it would keep on retrying forever because refetchInterval is not 0
      setDownloadState((state) => ({
        ...state,
        refetchInterval: 0,
      }));
      return;
    }
    const status = transferStatus.data?.status;
    if (status === 'success') {
      setDownloadState((state) => ({
        ...state,
        refetchInterval: 0,
        downloadReady: true,
      }));
    } else if (status === 'error') {
      setDownloadState((state) => ({
        ...state,
        refetchInterval: 0,
      }));
    }
  }, [transferStatus.isError, transferStatus.data]);

  React.useEffect(() => {
    if (downloadState.downloadReady) onLoad(downloadState.downloadUrl);
  }, [downloadState.downloadReady]);

  const downloadFailed = queueDownload.isError || transferStatus.isError;
  if (downloadFailed) return <div>Unable to load document content</div>;
  if (
    queueDownload.isFetching ||
    transferStatus.isFetching ||
    transferStatus.data?.status === 'busy'
  )
    return <WUI.Loader className="documentContent__loader" />;

  if (downloadState.downloadReady)
    return (
      <div className="documentContent__content">
        {contentType.toLocaleLowerCase() === 'application/pdf' ? (
          <iframe
            title="pdf"
            width="100%"
            style={{ minHeight: '75vh' }}
            src={downloadState.downloadUrl}
            className="documentContent__frame"
          />
        ) : (
          <a href={downloadState.downloadUrl} download>
            Click here to download the file
          </a>
        )}
      </div>
    );

  return null;
};

export const HTMLContent = ({
  file,
  onLoad,
  documentId,
  installationIds,
  equipmentIds,
  search = '',
}: {
  file: File;
  search?: string;
  documentId: string;
  installationIds: string;
  equipmentIds: string;
  onLoad: (link: string) => void;
}): JSX.Element => {
  const location = useLocation();
  const rawHTML = useRawHTML(file.id);
  const resources = useResources(documentId, file.language);
  const [parsedHTML, setParsedHTML] = React.useState<string>();
  const paramString = location.search;
  const qsParams = qs.parse(paramString);

  React.useEffect(() => {
    if (rawHTML.isFetching) setParsedHTML('');
  }, [rawHTML.isFetching]);

  const resourcesFetched =
    resources.isSuccess &&
    resources.data.every((resource: UseQueryResult) => resource.isSuccess);

  React.useEffect(() => {
    if (resourcesFetched && rawHTML.isSuccess) {
      let html = rawHTML.data;

      html = html.replaceAll(
        'href="http',
        `target="_blank" rel="noopener noreferrer" href="http`
      );

      html = html.replaceAll(
        'href="@@@LINK:',
        `target="_blank" rel="noopener noreferrer" href="${window.origin}?doc=`
      );

      resources.data.forEach(
        (
          resource: UseQueryResult<{
            resourceId: string;
            url: string;
          }>
        ) => {
          if (resource.isSuccess) {
            html = html.replaceAll(resource.data.resourceId, resource.data.url);
          }
        }
      );

      html = html.replaceAll(
        'href="/apex/Portal_TIChapterRedirect?',
        `target="_blank" rel="noopener noreferrer" href="${window.origin}/document?id=${documentId}&language=${file.language}&equipment=${equipmentIds}&installation=${installationIds}&`
      );

      if (search) {
        html = highlight({ content: html, keyword: search });
      }

      setParsedHTML(html);
    }
  }, [resourcesFetched, resources.data, rawHTML.isSuccess, rawHTML.data]);

  const html = useHTML(file.id, documentId, parsedHTML);

  React.useEffect(() => {
    if (html.isSuccess) {
      onLoad(html.data);
    }
  }, [html.isSuccess, html.data]);

  if (rawHTML.isError || html.isError)
    return <div>Unable to load document content</div>;

  if (!resourcesFetched || rawHTML.isFetching || html.isFetching)
    return <WUI.Loader className="documentContent__loader" />;

  if (html.isSuccess)
    return (
      <div className="documentContent__content">
        <iframe
          title="html"
          className="documentContent__frame"
          src={`${html.data}#${qsParams.anchor}`}
        />
      </div>
    );

  return <div />;
};

export const DocumentContent = ({
  document,
  search = '',
  selectedEquipmentIds,
  selectedInstallationIds,
}: {
  search?: string;
  document: Document;
  selectedEquipmentIds: string;
  selectedInstallationIds: string;
}): JSX.Element => {
  const [fileIndex, setFileIndex] = React.useState(0);
  const [selectedFile, setFile] = React.useState<File>();
  const [language, setLanguage] = React.useState<OptionTypeBase>();
  const [languages, setLanguages] = React.useState<OptionTypeBase[]>([]);

  const documentTrackingData = useTrackDocument(document.id, {
    enabled: true,
    language: language?.value,
  });

  /* linkToAttchments retrieved when single document is opened so as not to overburden api */
  const linkToAttachments = documentTrackingData?.data?.linkToAttachments;

  const htmlExists = document.files.some((file) => file.type === 'text/html');

  const filesInOrder = orderBy(
    document.files,
    htmlExists
      ? (file) => file.type !== 'text/html'
      : (file) => file.type !== 'application/pdf'
  ).filter((file) => file.type); // Remove files that don't have a type. It means that the response is probably broken

  const pdfFilesInOrder = filesInOrder.filter(
    (file) => file.type === 'application/pdf'
  );

  React.useEffect(() => {
    const possibleLanguages: OptionTypeBase[] = uniqWith(
      filesInOrder.map((file) => ({
        value: file.language,
        label: get(documentContentDictionary, file.language) || file.language,
      })),
      isEqual
    );

    const [firstLanguage] = possibleLanguages;
    const englishLanguage = possibleLanguages.find((lang) =>
      lang.value.includes('en')
    );

    setLanguages(possibleLanguages);
    setLanguage(englishLanguage || firstLanguage);
  }, []);

  React.useEffect(() => {
    if (language) {
      const selectedLanguageFiles = filesInOrder.filter(
        (file) => file.language === language.value
      );

      const foundFile =
        selectedLanguageFiles[fileIndex] ||
        selectedLanguageFiles.find((file) => file.language === language.value);

      setFile(foundFile);
    }
  }, [language]);

  const [contentURL, setContentURL] = React.useState('');

  const isHTMLSelected = selectedFile?.type === 'text/html';
  const isPDFSelected = selectedFile?.type === 'application/pdf';
  const numberOfPDFFilesInDocument = document.files.filter(
    (file) => file.type === 'application/pdf' && file.language.includes('en_')
  ).length;
  const isMultiPDFDocument = numberOfPDFFilesInDocument > 1;
  const isLargeMultiPDFDocument = numberOfPDFFilesInDocument > 10; // dropdown functionality if #pdf>10

  const getIconClassNames = (active: boolean): string =>
    isMultiPDFDocument
      ? cx({
          documentContent__icon_multi_pdf: true,
          documentContent__icon_active_multi_pdf: active,
        })
      : cx({
          documentContent__icon: true,
          documentContent__icon_active: active,
        });

  const getIconVariant = (selected: boolean): WUI.ButtonVariant => {
    if (isMultiPDFDocument) {
      return selected ? WUI.ButtonVariant.Gray : WUI.ButtonVariant.Error;
    }
    return selected ? WUI.ButtonVariant.Primary : WUI.ButtonVariant.Outline;
  };

  const renderHeader = (): JSX.Element => {
    const selectedLanguageFiles = filesInOrder.filter(
      (file) => file.language === language?.value
    );

    return (
      <div className="documentContent__header">
        {isMultiPDFDocument && (
          <span className="documentContent__multifile_message">{`${numberOfPDFFilesInDocument} PDF files found`}</span>
        )}
        <div className="documentContent__navigation">
          {selectedFile && !isLargeMultiPDFDocument
            ? selectedLanguageFiles.map((file, index) => {
                const selected = file.name === selectedFile.name;
                const iconClassNames = getIconClassNames(selected);
                const variant = getIconVariant(selected);

                const fileType = file.type.split('/')[1].toUpperCase();
                const isPDF = fileType === 'PDF';
                const isHTML = fileType === 'HTML';

                const lastIndexOfDot = file.name.lastIndexOf('.');
                const fileExt =
                  lastIndexOfDot === -1
                    ? 'Unknown'
                    : file.name.slice(lastIndexOfDot + 1);

                if (selectedLanguageFiles.length === 1) return null;

                if (isHTML) {
                  return (
                    <WUI.Button
                      title={file.name}
                      variant={variant}
                      onClick={() => {
                        setContentURL('');
                        setFile(file);
                        setFileIndex(index);
                      }}
                      className="documentContent__button">
                      <HTMLIconSVG className={iconClassNames} />
                      <span>{fileExt.toUpperCase()}</span>
                      {document.hasVideo && (
                        <VideoIconSVG className={iconClassNames} />
                      )}
                    </WUI.Button>
                  );
                }

                if (isPDF) {
                  return (
                    <WUI.Button
                      title={file.name}
                      variant={variant}
                      onClick={() => {
                        setContentURL('');
                        setFile(file);
                        setFileIndex(index);
                      }}
                      className="documentContent__button">
                      <PDFIconSVG className={iconClassNames} />
                      <span>{fileExt.toUpperCase()}</span>
                    </WUI.Button>
                  );
                }

                return (
                  <WUI.Button
                    title={file.name}
                    variant={variant}
                    onClick={() => {
                      setContentURL('');
                      setFile(file);
                      setFileIndex(index);
                    }}
                    className="documentContent__button">
                    <DocIconSVG className={iconClassNames} />
                    <span>{fileExt.toUpperCase()}</span>
                  </WUI.Button>
                );
              })
            : null}

          {isLargeMultiPDFDocument && (
            <div className="documentContent__multifile_autocomplete">
              <WUI.AutoComplete
                options={pdfFilesInOrder.map((pdfFile) => ({
                  value: pdfFile.name,
                  label: pdfFile.name,
                }))}
                defaultValue={{
                  value: pdfFilesInOrder[0].name,
                  label: pdfFilesInOrder[0].name,
                }}
                onChange={(selectedPDFFile) => {
                  const foundFile =
                    selectedPDFFile !== null && 'value' in selectedPDFFile
                      ? pdfFilesInOrder.find(
                          (pdfFile) => pdfFile.name === selectedPDFFile.value
                        )
                      : undefined;
                  setContentURL('');
                  setFile(foundFile);
                }}
              />
            </div>
          )}

          {contentURL && (isPDFSelected || isHTMLSelected) ? (
            <a rel="noreferrer" href={contentURL} target="_blank">
              Open document in new window
            </a>
          ) : null}
        </div>
        <div className="documentContent__language-selector">
          <WUI.AutoComplete
            options={languages}
            defaultValue={language}
            onChange={(lang) => (lang ? setLanguage(lang) : null)}
          />
        </div>
      </div>
    );
  };

  if (language) {
    return (
      <div className="documentContent">
        {/* Show the highlighted message only when linkToAttachments is available and only for SWR */}
        {document.subType === 'Service Work Report' && linkToAttachments && (
          <div className="faq__highlighted">
            This Service Work Report has attachments,
            <a href={linkToAttachments} target="_blank" rel="noreferrer">
              {' '}
              click here{' '}
            </a>
            to view attachments (requires Salesforce license).
          </div>
        )}

        {renderHeader()}

        {selectedFile ? (
          selectedFile.type === 'text/html' ? (
            <iframe
              title="html"
              width="100%"
              style={{ border: 0, minHeight: '75vh' }}
              onLoad={() =>
                setContentURL(
                  `${window.location.origin}/document?id=${document.id}&fileId=${selectedFile.id}&language=${selectedFile.language}&search=${search}&equipment=${selectedEquipmentIds}&installation=${selectedInstallationIds}`
                )
              }
              src={`${window.location.origin}/document?id=${document.id}&fileId=${selectedFile.id}&language=${selectedFile.language}&search=${search}&equipment=${selectedEquipmentIds}&installation=${selectedInstallationIds}`}
            />
          ) : (
            <Content
              file={selectedFile}
              onLoad={(link) =>
                setContentURL(
                  `${window.location.origin}/pdf?link=${encodeURIComponent(
                    link
                  )}`
                )
              }
              documentId={document.id}
              contentType={selectedFile.type}
            />
          )
        ) : (
          <div>No document/translation available</div>
        )}
      </div>
    );
  }

  if (document.subType === 'Technical FAQ') {
    return (
      <div className="faq">
        <div>
          <div className="faq__title">Question</div>
          <div
            className="faq__content"
            dangerouslySetInnerHTML={{
              __html: highlight({
                keyword: search,
                content: document.question,
              }),
            }}
          />
        </div>
        <div>
          <div className="faq__title">Answer</div>
          <div
            className="faq__content"
            dangerouslySetInnerHTML={{
              __html: highlight({ keyword: search, content: document.answer }),
            }}
          />
        </div>
      </div>
    );
  }

  if (document.subType === 'Closed TechRequest') {
    return (
      <div className="faq">
        {/* Show the highlighted message only when linkToAttachments is available */}
        {linkToAttachments && (
          <div className="faq__highlighted">
            This Closed TechRequest has attachments,
            <a href={linkToAttachments} target="_blank" rel="noreferrer">
              {' '}
              click here{' '}
            </a>
            to view attachments (requires Salesforce license).
          </div>
        )}
        <div className="faq__title">TechRequest data</div>
        <div className="faq__content faq__row">
          <div>
            <div className="faq__subtitle">Status:</div>
            <div className="faq__subcontent">{document.status}</div>
          </div>
          <div>
            <div className="faq__subtitle">Answered at:</div>
            <div className="faq__subcontent">
              {moment(document.answered).format('DD.MM.YYYY HH:mm')}
            </div>
          </div>
          <div>
            <div className="faq__subtitle">Created by:</div>
            <div className="faq__subcontent">{document.createdBy}</div>
          </div>

          <div>
            <div className="faq__subtitle">Created at:</div>
            <div className="faq__subcontent">
              {moment(document.created).format('DD.MM.YYYY HH:mm')}
            </div>
          </div>
        </div>

        <div>
          <div className="faq__title">Request</div>
          <div className="faq__content">
            <div className="faq__subtitle">Subject:</div>
            <div
              className="faq__subcontent"
              dangerouslySetInnerHTML={{
                __html: highlight({
                  keyword: search,
                  content: document.subject,
                }),
              }}
            />
            <div className="faq__subtitle">Description:</div>
            <div
              className="faq__subcontent"
              dangerouslySetInnerHTML={{
                __html: highlight({
                  keyword: search,
                  content: document.techRequestDescription,
                }),
              }}
            />
          </div>
        </div>
        <div className="faq__title">Wärtsilä Answer</div>
        <div className="faq__content">
          <div className="faq__subtitle">Answer:</div>
          <div
            className="faq__subcontent"
            dangerouslySetInnerHTML={{
              __html: highlight({
                keyword: search,
                content: document.wartsilaAnswer,
              }),
            }}
          />
        </div>
      </div>
    );
  }

  return <div>No content found</div>;
};
