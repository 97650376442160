import { DocumentDictionary } from './documents.types';

export const documentDictionary: DocumentDictionary = {
  id: 'Document Id',
  tkbURL: 'TKB URL',
  documentDate: 'Date',
  title: 'Document title',
  mainType: 'Document type',
  subType: 'Document subtype',
  visibility: 'Document category',
  documentNumber: 'Document number',
  numberOfChildren: 'Number of documents',
  wartsilaOnlineURL: 'Wärtsilä Online URL',
  productReferenceTypes: 'Product reference type',
};
